import { API_BASE_URL, buildOptionsWithAuth } from "util/Constants.js";
import moment from "moment";

export function getAuditLogs(centerName,resourceType,actionType, startDate, endDate, username, resourceData, pageNumber) {
  var path_resourceType = ""
  var path_actionType = ""
  var path_startDate = ""
  var path_endDate = ""
  var path_username = ""
  var path_resourceData = ""
  var path_pageNumber = ""
  

  if (resourceType !== null && resourceType !== undefined)
    path_resourceType = "&resource-type=" + resourceType

  if (actionType !== null && actionType !== undefined)
    path_actionType = "&action-type=" + actionType

  if (startDate !== null && startDate !== undefined)
    path_startDate = "&start-date=" + moment(startDate).format('YYYY-MM-DD HH:mm:ss').toString()

  if (endDate !== null && endDate !== undefined)
    path_endDate = "&end-date=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss').toString()
  
  if (username !== null && username !== undefined)
    path_username = "&username=" + username
  
  if (resourceData !== null && resourceData !== undefined)
    path_resourceData = "&resource-data=" + resourceData
  
  path_pageNumber = "&page=" + pageNumber
  


  const url = API_BASE_URL + "/audit-logs?center-name=" + centerName + path_resourceType + path_actionType + path_startDate + path_endDate + path_username + path_resourceData + path_pageNumber;
  const headers = buildOptionsWithAuth("GET");

  return fetch(url, headers)
    .then(async (response) => {
      if (response.status === 500) throw new Error("Internal server error");
      return response.json();
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}

export function getAllActionTypes() {
  const url = API_BASE_URL + "/audit-logs/action-types";
  const headers = buildOptionsWithAuth("GET");

  return fetch(url, headers)
    .then(async (response) => {
      if (response.status === 500) throw new Error("Internal server error");
      return response.json();
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}

export function getAllResourceTypes() {
  const url = API_BASE_URL + "/audit-logs/resource-types";
  const headers = buildOptionsWithAuth("GET");

  return fetch(url, headers)
    .then(async (response) => {
      if (response.status === 500) throw new Error("Internal server error");
      return response.json();
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}
