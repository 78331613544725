import React, { Component } from 'react';

import AddEmail from 'components/AddEmail.jsx';

import * as UserRepository from '../../repository/user/UserRepository';
import {
  Spin,
  Descriptions,
  Form,
  Input,
  notification,
  Breadcrumb,
  Row,
  Space,
  Col,
  Card,
  Select,
  Tooltip,
  Switch,
} from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MdAccountCircle } from 'react-icons/md';
import { withTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/es';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import colors from 'components/Buttons/colors';
moment.locale('es');

const languages = [
  {
    value: 'es',
    label: 'ES - Español',
    disabled: false,
  },
  {
    value: 'ca',
    label: 'CA - Català',
    disabled: false,
  },
  {
    value: 'ga',
    label: 'GA - Galego',
    disabled: false,
  },
  {
    value: 'eus',
    label: 'EUS - Euskera',
    disabled: false,
  },
];

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    const antIcon = (
      <LoadingOutlined
        style={{ fontSize: 24 }}
        spin
      />
    );

    const changePassword = (values) => {
      UserRepository.updatePassword(values.password)
        .then(() => {
          this.setState({ loading: false });
          notification['success']({
            message: 'LPRConnect App',
            description: this.props.t('profile.update.success'),
          });
        })
        .catch((error) => {
          if (error.message === 'La contrasenya no pot ser la mateixa que la anterior') {
            notification['error']({
              message: 'ZinkinData Portal',
              description: this.props.t('profile.password.same-password-error'),
            });
          } else {
            notification['error']({
              message: 'ZinkinData Portal',
              description: this.props.t('profile.update.error'),
            });
          }
          this.setState({ loading: false });
        });
    };

    const error_validation_password_message = this.props.t('profile.password.password-pattern');
    const confirm_password_message = this.props.t('profile.password.confirm-password-error');

    return (
      <div className="main-content">
        {this.state.alert}
        {this.props.currentUser.email === '' ||
        this.props.currentUser.email === null ||
        this.props.currentUser.email === undefined ? (
          <Row>
            <AddEmail {...this.props} />
          </Row>
        ) : null}
        <Spin
          spinning={this.state.loading}
          indicator={antIcon}
        >
          <Breadcrumb
            separator=">"
            style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
          >
            <Breadcrumb.Item>
              <div style={{ display: 'inline-block', height: '30px' }}>
                <i
                  style={{
                    lineHeight: '30px',
                    verticalAlign: 'middle',
                    marginRight: '5px',
                    fontSize: '30px',
                  }}
                >
                  <MdAccountCircle />
                </i>
                {this.props.t('sidebar.profile')}
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={'large'}
          >
            <Row>
              <Col
                xs={{ flex: '100%' }}
                sm={{ flex: '100%' }}
                md={{ flex: '50%' }}
                lg={{ flex: '50%' }}
                xl={{ flex: '50%' }}
              >
                <Card title={this.props.t('profile.me.title')}>
                  <Descriptions
                    style={{ marginBottom: '13px' }}
                    size={'middle'}
                    bordered
                  >
                    <Descriptions.Item
                      span={3}
                      label={this.props.t('profile.me.dni')}
                    >
                      {this.props.currentUser.username}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={3}
                      label={this.props.t('profile.me.name')}
                    >
                      {this.props.currentUser.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={3}
                      label={this.props.t('profile.me.surnames')}
                    >
                      {this.props.currentUser.surnames}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={3}
                      label={this.props.t('profile.me.email')}
                    >
                      {this.props.currentUser.email}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={3}
                      label={this.props.t('profile.me.phone')}
                    >
                      {this.props.currentUser.phone}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={3}
                      label={this.props.t('profile.me.language')}
                    >
                      <Select
                        size="large"
                        defaultValue={this.props.currentUser.language}
                        options={languages}
                        onChange={(event) => {
                          UserRepository.changeLanguage(localStorage.getItem('center'), event)
                            .then(() => {
                              window.location.reload();
                            })
                            .catch(() => {
                              notification['error']({
                                message: 'LPRConnect App',
                                description: this.props.t('profile.update.error-language'),
                              });
                            });
                        }}
                      />
                    </Descriptions.Item>
                    {this.props.currentUser.email ? (
                      <Descriptions.Item
                        span={3}
                        label={
                          <>
                            {this.props.t('profile.me.mfa')}
                            <Tooltip
                              title={this.props.t('profile.me.mfa-tooltip')}
                              placement="right"
                              color={colors.blue.main}
                            >
                              <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                            </Tooltip>
                          </>
                        }
                      >
                        <Switch
                          loading={this.state.switchLoading}
                          defaultValue={this.props.currentUser.mfa}
                          onClick={(event) => {
                            this.setState({ switchLoading: true });
                            UserRepository.changeMfa(localStorage.getItem('center'), event)
                              .then(() => {})
                              .catch(() => {});
                            this.setState({ switchLoading: false });
                          }}
                        />
                      </Descriptions.Item>
                    ) : null}
                  </Descriptions>
                </Card>
              </Col>
              <Col
                xs={{ flex: '100%' }}
                sm={{ flex: '100%' }}
                md={{ flex: '50%' }}
                lg={{ flex: '50%' }}
                xl={{ flex: '50%' }}
              >
                <Card title={this.props.t('profile.password.title')}>
                  <Form
                    layout="vertical"
                    onFinish={changePassword}
                  >
                    <Form.Item
                      name="password"
                      label={this.props.t('profile.password.new')}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: this.props.t('profile.password.password-required'),
                        },
                        () => ({
                          validator(_, value) {
                            if (value.length < 6) {
                              return Promise.reject(new Error(error_validation_password_message));
                            }

                            const patron = new RegExp(
                              '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-_=+#~])[A-Za-z\\d@$!%*?&\\-_=+#~]+$'
                            );
                            if (!patron.test(value)) {
                              return Promise.reject(new Error(error_validation_password_message));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      label={this.props.t('profile.password.confirm')}
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: this.props.t('profile.password.password-required'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(confirm_password_message));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <ButtonPrimary
                      color="black"
                      shape="round"
                      size="large"
                      htmlType="submit"
                      style={{ width: '100%' }}
                    >
                      {this.props.t('profile.password.update')}
                    </ButtonPrimary>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Space>
        </Spin>
      </div>
    );
  }
}

export default withTranslation('common')(Profile);
