import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from 'components/Card.jsx';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, notification, Select, Breadcrumb, Radio, Input } from 'antd';
import { v4 } from 'uuid';

import * as ZoneAuthorizationsRepository from 'repository/authoritzations/ZoneAuthorizationsRepository';
import { getAllZonesIlla } from 'util/APIUtils';
import { MdAdd, MdPeople } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

const { TextArea } = Input;
const { Option } = Select;
const radioStyle = {
  height: '30px',
  lineHeight: '30px',
  fontWeight: 'normal',
};

class ZoneAuthoritzationsCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.location.state.username,
      loading: true,
      zones: [],
      selectedZoneIds: [],
      zoneError: null,

      reasonValueSelected: this.props.t('zone-authorizations.admin.create.reasons.resident'),
      reasonOptionSelected: 1,
      reasonValueOptions: [
        this.props.t('zone-authorizations.admin.create.reasons.resident'),
        this.props.t('zone-authorizations.admin.create.reasons.owner'),
        this.props.t('zone-authorizations.admin.create.reasons.reduced-mobility'),
      ],
    };

    this.addZoneAuthorization = this.addZoneAuthorization.bind(this);
  }

  componentDidMount() {
    getAllZonesIlla(localStorage.getItem('center'))
      .then((zonesResponse) => {
        this.setState({
          zones: zonesResponse,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  addZoneAuthorization() {
    if (this.state.selectedZoneIds.length <= 0) {
      this.setState({
        zoneError: (
          <small className="text-danger">{this.props.t('zone-authorizations.admin.create.required-zone')}</small>
        ),
      });
    } else {
      this.createAuthoritzation();
      this.setState({ zoneError: undefined });
    }
  }

  async createAuthoritzation() {
    const requests = [];
    this.state.selectedZoneIds.forEach((zoneId) => {
      requests.push(
        ZoneAuthorizationsRepository.postZoneAuthorization(
          v4(),
          this.state.username,
          this.state.reasonValueSelected,
          zoneId
        )
      );
    });

    await Promise.all(requests)
      .then(() => {
        notification['success']({
          message: 'ZinkinData Portal',
          description: this.props.t('zone-authorizations.admin.create.success-create'),
        });

        this.props.history.push({
          pathname: '/authoritzations',
          state: { username: this.state.username },
        });
      })
      .catch((error) => console.log(error));
  }

  handleZoneSelectionChange = (record, value) => {
    this.setState({ selectedZoneIds: record });
  };

  render() {
    if (this.props.currentUser.role !== 'ROLE_ADMIN' && this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const zonesOptions = this.state.zones.map((zone) => (
        <Option
          key={zone.id}
          centername={zone.centername}
        >
          {zone.name}
        </Option>
      ));
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      return (
        <div className="main-content">
          {this.state.alert}
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Breadcrumb
                    separator=">"
                    style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
                  >
                    <Breadcrumb.Item
                      className="menu-history"
                      onClick={() => this.props.history.push('/users')}
                    >
                      <div style={{ display: 'inline-block', height: '30px' }}>
                        <i
                          style={{ lineHeight: '30px', verticalAlign: 'middle', marginRight: '5px', fontSize: '30px' }}
                        >
                          <MdPeople />
                        </i>
                        {this.props.t('sidebar.citizen')}
                      </div>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      className="menu-history"
                      onClick={() =>
                        this.props.history.push({
                          pathname: '/authoritzations',
                          state: { username: this.state.username },
                        })
                      }
                    >
                      {this.props.t('sidebar.zone-authorizations')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{this.props.t('zone-authorizations.admin.create.title')}</Breadcrumb.Item>
                  </Breadcrumb>

                  <Card
                    content={
                      <div>
                        <legend>
                          <span>{this.props.t('zone-authorizations.admin.create.title-1')}</span>
                          <span>{this.state.username}</span>
                        </legend>

                        <Row style={{ margin: '25px 0' }}>
                          <Col md={6}>
                            <span className="title-select">
                              {this.props.t('zone-authorizations.admin.create.reason-title')}
                            </span>
                            <div id="radio-buttons-petition">
                              <Radio.Group
                                onChange={(event) => {
                                  this.setState({
                                    reasonOptionSelected: event.target.value,
                                    reasonValueSelected: this.state.reasonValueOptions[event.target.value - 1],
                                  });
                                }}
                                value={this.state.reasonOptionSelected}
                              >
                                <div>
                                  <Radio
                                    style={radioStyle}
                                    value={1}
                                  >
                                    {this.state.reasonValueOptions[0]}
                                  </Radio>
                                </div>
                                <div>
                                  <Radio
                                    style={radioStyle}
                                    value={2}
                                  >
                                    {this.state.reasonValueOptions[1]}
                                  </Radio>
                                </div>
                                <div>
                                  <Radio
                                    style={radioStyle}
                                    value={3}
                                  >
                                    {this.state.reasonValueOptions[2]}
                                  </Radio>
                                </div>
                                <div>
                                  <Radio
                                    style={radioStyle}
                                    value={4}
                                  >
                                    {this.props.t('zone-authorizations.admin.create.reasons.other')}
                                  </Radio>
                                  {this.state.reasonOptionSelected === 4 ? (
                                    <TextArea
                                      onChange={(event) =>
                                        this.setState({
                                          reasonValueSelected: event.target.value,
                                        })
                                      }
                                      value={this.state.reasonValueSelected}
                                    />
                                  ) : null}
                                </div>
                              </Radio.Group>
                            </div>
                          </Col>
                          <Col md={6}>
                            <span className="title-select">
                              {this.props.t('zone-authorizations.admin.create.zone')}
                            </span>
                            <Select
                              mode="multiple"
                              size="large"
                              value={this.state.selectedZoneIds}
                              style={{ height: '40px' }}
                              optionFilterProp="children"
                              placeholder={this.props.t('zone-authorizations.admin.create.zone-placeholder')}
                              onChange={this.handleZoneSelectionChange}
                            >
                              {zonesOptions}
                            </Select>
                            {this.state.zoneError}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <ButtonPrimary
                              style={{
                                margin: '10px 0 20px 0',
                              }}
                              color="blue"
                              shape="round"
                              size="large"
                              onClick={() => this.addZoneAuthorization()}
                            >
                              {this.props.t('zone-authorizations.admin.create.add-button')}
                              <MdAdd
                                style={{
                                  verticalAlign: 'sub',
                                  fontSize: '20',
                                  marginLeft: '5px',
                                }}
                              />
                            </ButtonPrimary>
                          </Col>
                        </Row>
                      </div>
                    }
                  />
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(ZoneAuthoritzationsCreate);
