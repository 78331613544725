import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar"; // this is used to create scrollbars on windows devices like the ones from apple devices
import "perfect-scrollbar/css/perfect-scrollbar.css";
import dashboardRoutes from "routes/dashboard.jsx"; // dinamically create dashboard routes

import Sidebar from "components/Sidebar.jsx";
import Header from "components/Header.jsx";
import Footer from "components/Footer.jsx";

var ps;

class Dashboard extends Component {

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  }

  render() {
    return (
      <div className="wrapper">
        <Sidebar {...this.props} />
        <div
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
          <Switch>
            {dashboardRoutes.map((view, key) => {
              if (view.collapse) {
                return view.views.map((subView, key) => {
                  return (
                    <Route
                      path={subView.path}
                      key={key}
                      render={props => (
                        <subView.component
                          handleLogout={this.props.handleLogout}
                          currentUser={this.props.currentUser}
                          currentCenter={this.props.currentCenter}
                          {...props}
                        />
                      )}
                    />
                  );
                });
              } else {
                return (
                  <Route
                    path={view.path}
                    key={key}
                    render={props => (
                      <view.component
                        {...props}
                        currentUser={this.props.currentUser}
                        currentCenter={this.props.currentCenter}
                        handleLogout={this.props.handleLogout}
                      />
                    )}
                  />
                );

              }
            })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Dashboard;
