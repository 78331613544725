import { Button } from 'antd';
import React, { useState } from 'react';
import colors from './colors';

export default function ButtonLink({ style, color, ...restProps }) {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    restProps.onMouseEnter && restProps.onMouseEnter();
    setHovered(true);
  };

  const handleMouseLeave = () => {
    restProps.onMouseLeave && restProps.onMouseLeave();
    setHovered(false);
  };

  const colorProperty = {
    color: hovered ? colors[color].lighter : colors[color].main,
  };

  return (
    <Button
      {...restProps}
      type="link"
      style={{ ...style, ...colorProperty }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
}
