import React, { Component } from 'react';
import { Input } from "antd";

export default class LicensePlateInput extends Component {

    handleChange = (event) => {
        this.props.onChange(event.target.value.toUpperCase().replace(/ /g, '').replace("-", ""))
    }

    render() {
        return (
            <React.Fragment>
                <Input
                    style={{ marginBottom: "5px" }}
                    size="large"
                    placeholder="Ex: 1234ABC"
                    onChange={this.handleChange}
                    value={this.props.value}
                />
            </React.Fragment>
        )
    }
}
