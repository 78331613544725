import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Spin, Table, Input, Space, notification, Breadcrumb, Switch, Tooltip, Modal, Flex } from 'antd';
import {
  LoadingOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from 'react-bootstrap-sweetalert';
import Card from 'components/Card.jsx';
import { withTranslation } from 'react-i18next';

import * as UserRepository from '../../repository/user/UserRepository';
import { RiShieldUserLine } from 'react-icons/ri';
import { MdAdd, MdLockReset } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ButtonLink from 'components/Buttons/ButtonLink';
import { GoUnverified, GoVerified } from 'react-icons/go';
import colors from 'components/Buttons/colors';
import { TbEdit, TbTrashXFilled } from 'react-icons/tb';

class AdminUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      usersData: [],
      totalAdminUsers: 0,
    };

    this.columns = [
      {
        title: this.props.t('admin-users.list.table.enable'),
        dataIndex: 'enable',
        key: 'enable',
        align: 'center',
        render: (text, record) => {
          return (
            <Switch
              disabled={!this.props.currentUser.isSuperAdmin() && !this.props.currentUser.isAdmin()}
              checked={record.enable}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onClick={() => {
                this.setState({ loading: true });
                UserRepository.changeUserEnable(localStorage.getItem('center'), record.id, !record.enable)
                  .then(() => {
                    this.getUsersList();
                    notification['success']({
                      message: 'ZinkinData Portal',
                      description:
                        !record.enable === true
                          ? this.props.t('citizens.list.table.enable-errors.to-enable.success')
                          : this.props.t('citizens.list.table.enable-errors.to-disable.success'),
                    });
                  })
                  .catch(() => {
                    this.setState({ loading: false });
                    notification['error']({
                      message: 'ZinkinData Portal',
                      description:
                        !record.enable === true
                          ? this.props.t('citizens.list.table.enable-errors.to-enable.error')
                          : this.props.t('citizens.list.table.enable-errors.to-disable.error'),
                    });
                  });
              }}
            />
          );
        },
      },
      {
        title: this.props.t('admin-users.list.table.verified.title'),
        dataIndex: 'verified',
        key: 'verified',
        align: 'center',
        width: '7%',
        render: (_, record) => {
          return record.verified ? (
            <Tooltip
              color={colors.blue.main}
              title={this.props.t('admin-users.list.table.verified.true')}
            >
              <GoVerified style={{ fontSize: '22', color: 'green' }} />
            </Tooltip>
          ) : (
            <Tooltip
              color={colors.blue.main}
              title={this.props.t('admin-users.list.table.verified.false')}
            >
              <GoUnverified style={{ fontSize: '22', color: 'red' }} />
            </Tooltip>
          );
        },
      },
      {
        title: this.props.t('admin-users.list.table.dni'),
        dataIndex: 'username',
        key: 'username',
        ...this.getColumnSearchProps('username', this.props.t('admin-users.list.table.dni')),
      },
      {
        title: this.props.t('admin-users.list.table.name'),
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name', this.props.t('admin-users.list.table.name')),
      },
      {
        title: this.props.t('admin-users.list.table.surnames'),
        dataIndex: 'surnames',
        key: 'surnames',
        ...this.getColumnSearchProps('surnames', this.props.t('admin-users.list.table.surnames')),
      },
      {
        title: this.props.t('admin-users.list.table.email'),
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email', this.props.t('admin-users.list.table.email')),
      },
      {
        title: this.props.t('admin-users.list.table.actions.title'),
        dataIndex: 'acciones',
        align: 'center',
        render: (text, { id, username, name, surnames, email }) => {
          return (
            <Flex
              justify="center"
              align="center"
            >
              <ButtonLink
                color="blue"
                onClick={() => {
                  Modal.confirm({
                    title: this.props.t('admin-users.list.table.actions.reset-title'),
                    icon: <ExclamationCircleFilled />,
                    content: this.props.t('admin-users.list.table.actions.reset-description'),
                    centered: true,
                    cancelText: this.props.t('admin-users.list.table.actions.cancel'),
                    okText: this.props.t('admin-users.list.table.actions.ok'),
                    okButtonProps: {
                      className: 'button-primary-props-blue',
                      shape: 'round',
                    },
                    cancelButtonProps: {
                      className: 'button-primary-props-white',
                      shape: 'round',
                    },
                    onOk: () => {
                      UserRepository.resetCredentials(localStorage.getItem('center'), id)
                        .then(() => {
                          notification['success']({
                            message: 'ZinkinData Portal',
                            description: this.props.t('admin-users.list.table.actions.success-message'),
                          });
                        })
                        .catch(() => {
                          notification['error']({
                            message: 'ZinkinData Portal',
                            description: this.props.t('admin-users.list.table.actions.error-message'),
                          });
                        });
                    },
                  });
                }}
              >
                <Tooltip
                  color={colors.blue.main}
                  title={this.props.t('admin-users.list.table.actions.reset')}
                >
                  <MdLockReset style={{ fontSize: '30' }} />
                </Tooltip>
              </ButtonLink>
              <ButtonLink
                color="grey"
                onClick={() => {
                  let user = { username, name, surnames, email };

                  this.props.history.push({
                    pathname: '/admin-users-edit',
                    state: {
                      user: user,
                    },
                  });
                }}
              >
                <Tooltip
                  color={colors.blue.main}
                  title={this.props.t('admin-users.list.table.actions.edit')}
                >
                  <TbEdit style={{ fontSize: '30' }} />
                </Tooltip>
              </ButtonLink>
              <ButtonLink
                color="black"
                onClick={() => {
                  this.warningDeleteUser(username);
                }}
              >
                <Tooltip
                  color={colors.blue.main}
                  title={this.props.t('admin-users.list.table.actions.delete')}
                >
                  <TbTrashXFilled style={{ fontSize: '30' }} />
                </Tooltip>
              </ButtonLink>
            </Flex>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.getUsersList();
  }

  async getUsersList() {
    try {
      const response = await UserRepository.getAdminUsersFromCenter(localStorage.getItem('center'));
      this.setState({
        usersData: response,
        loading: false,
        totalAdminUsers: response.length,
      });
    } catch (error) {
      console.log(error);
    }
  }

  getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.props.t('admin-users.list.table.filter-title') + ' ' + title}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonPrimary
            type="primary"
            fill
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <SearchOutlined /> {this.props.t('admin-users.list.search-button')}
          </ButtonPrimary>
          <ButtonPrimary
            color="black"
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('admin-users.list.reset-button')}
          </ButtonPrimary>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  });

  warningDeleteUser(username) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('admin-users.list.delete.modal.title')}
          onConfirm={() => this.deleteUser(username)}
          onCancel={() => this.cancelDelete()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText={this.props.t('admin-users.list.delete.modal.confirm-button')}
          cancelBtnText={this.props.t('admin-users.list.delete.modal.cancel-button')}
          showCancel
        />
      ),
    });
  }

  cancelDelete() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block' }}
          title={this.props.t('admin-users.list.delete.cancel-modal.title')}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.props.t('admin-users.list.delete.cancel-modal.message')}
        </SweetAlert>
      ),
    });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  async deleteUser(username) {
    try {
      await UserRepository.deleteUserByUsername(username);

      this.hideAlert();
      notification['success']({
        message: 'ZinkinData Portal',
        description: this.props.t('admin-users.list.delete.success'),
      });

      this.getUsersList();
    } catch (error) {
      notification['error']({
        message: 'ZinkinData Portal',
        description: this.props.t('admin-users.list.delete.error'),
      });
    }
  }

  render() {
    if (this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      return (
        <div className="main-content">
          {this.state.alert}
          <Grid fluid>
            <Breadcrumb
              separator=">"
              style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
            >
              <Breadcrumb.Item>
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i style={{ lineHeight: '30px', verticalAlign: 'middle', marginRight: '5px', fontSize: '30px' }}>
                    <RiShieldUserLine />
                  </i>
                  {this.props.t('sidebar.admin-users')} <b>({this.state.totalAdminUsers})</b>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Card
                    content={
                      <div>
                        <Row>
                          <Col md={1}>
                            <ButtonPrimary
                              color="blue"
                              shape="round"
                              size="large"
                              style={{
                                margin: '10px 0 20px 0',
                              }}
                              onClick={() => this.props.history.push('/admin-users-add')}
                            >
                              {this.props.t('admin-users.list.add-button')}
                              <MdAdd
                                style={{
                                  verticalAlign: 'sub',
                                  fontSize: '20',
                                  marginLeft: '5px',
                                }}
                              />
                            </ButtonPrimary>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Table
                              columns={this.columns}
                              dataSource={this.state.usersData}
                              pagination={{ pageSize: 100 }}
                              rowKey={() => {
                                return uuidv4();
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    }
                  />
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(AdminUserList);
