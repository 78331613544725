import React, { Component } from 'react';
import { LoadingOutlined, CheckOutlined, CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import {
  Spin,
  Table,
  notification,
  Breadcrumb,
  Input,
  Tag,
  Badge,
  Tooltip,
  Switch,
  Modal,
  Row as AntRow,
  Col as AntCol,
  Card as AntCard,
  Flex,
  Typography,
  Collapse,
} from 'antd';
import SweetAlert from 'react-bootstrap-sweetalert';

import * as UserRepository from '../../repository/user/UserRepository';
import { MdPeople, MdLocationOn, MdDirectionsCar, MdAdd, MdLockReset } from 'react-icons/md';
import { GoUnverified, GoVerified } from 'react-icons/go';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ButtonLink from 'components/Buttons/ButtonLink';

import { withTranslation } from 'react-i18next';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import colors from 'components/Buttons/colors';
import { TbEdit, TbTrashXFilled } from 'react-icons/tb';

class UserList extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: this.props.t('citizens.list.table.enable'),
        dataIndex: 'enable',
        key: 'enable',
        align: 'center',
        render: (text, record) => {
          return (
            <Switch
              disabled={!this.props.currentUser.isSuperAdmin() && !this.props.currentUser.isAdmin()}
              checked={record.enable}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onClick={() => {
                this.setState({ loading: true });
                UserRepository.changeUserEnable(localStorage.getItem('center'), record.id, !record.enable)
                  .then(() => {
                    this.searchByLicensePlate();
                    notification['success']({
                      message: 'ZinkinData Portal',
                      description:
                        !record.enable === true
                          ? this.props.t('citizens.list.table.enable-errors.to-enable.success')
                          : this.props.t('citizens.list.table.enable-errors.to-disable.success'),
                    });
                  })
                  .catch(() => {
                    this.setState({ loading: false });
                    notification['error']({
                      message: 'ZinkinData Portal',
                      description:
                        !record.enable === true
                          ? this.props.t('citizens.list.table.enable-errors.to-enable.error')
                          : this.props.t('citizens.list.table.enable-errors.to-disable.error'),
                    });
                  });
              }}
            />
          );
        },
      },
      {
        title: this.props.t('citizens.list.table.verified.title'),
        dataIndex: 'verified',
        key: 'verified',
        align: 'center',
        width: '7%',
        render: (_, record) => {
          return record.verified ? (
            <Tooltip
              color={colors.blue.main}
              title={this.props.t('citizens.list.table.verified.true')}
            >
              <GoVerified style={{ fontSize: '22', color: 'green' }} />
            </Tooltip>
          ) : (
            <Tooltip
              color={colors.blue.main}
              title={this.props.t('citizens.list.table.verified.false')}
            >
              <GoUnverified style={{ fontSize: '22', color: 'red' }} />
            </Tooltip>
          );
        },
      },
      {
        title: this.props.t('citizens.list.table.dni'),
        dataIndex: 'username',
        key: 'username',
        align: 'center',
      },
      {
        title: this.props.t('citizens.list.table.name'),
        dataIndex: 'name',
        key: 'name',
        align: 'center',
      },
      {
        title: this.props.t('citizens.list.table.surname'),
        dataIndex: 'surnames',
        key: 'surnames',
        align: 'center',
      },
      {
        title: this.props.t('citizens.list.table.email'),
        dataIndex: 'email',
        key: 'email',
        align: 'center',
      },
      {
        title: this.props.t('citizens.list.table.phone'),
        dataIndex: 'phone',
        key: 'phone',
        align: 'center',
      },
      {
        title: this.props.t('citizens.list.table.reduced-mobility'),
        dataIndex: 'reducedMobility',
        key: 'reducedMobility',
        align: 'center',
        render: (text, record) => {
          if (record.reducedMobility === true)
            return <Tag color="green">{this.props.t('citizens.list.filters.option-yes')}</Tag>;
          else return <Tag color="red">{this.props.t('citizens.list.filters.option-no')}</Tag>;
        },
        filters: [
          {
            text: this.props.t('citizens.list.filters.option-yes'),
            value: true,
          },
          {
            text: this.props.t('citizens.list.filters.option-no'),
            value: false,
          },
        ],
        onFilter: (value, record) =>
          record.reducedMobility === value || record.reducedMobility === undefined || record.reducedMobility === null,
      },
      {
        title: this.props.t('citizens.list.table.zone-authorizations'),
        dataIndex: 'schedule',
        key: 'schedule',
        align: 'center',
        render: (text, record) => {
          return (
            <ButtonLink
              color="black"
              onClick={() =>
                this.props.history.push({
                  pathname: '/authoritzations',
                  state: { username: record.username },
                })
              }
            >
              {record.pendingZoneAuthorizations !== 0 ? (
                <Tooltip
                  title={this.props.t('citizens.list.table.pending-tooltip')}
                  placement="rightTop"
                  color={colors.blue.main}
                >
                  <Badge
                    size="small"
                    offset={[-6, 5]}
                    status="success"
                    count={record.pendingZoneAuthorizations}
                    color="orange"
                  >
                    <MdLocationOn style={{ fontSize: '30' }} />
                  </Badge>
                </Tooltip>
              ) : (
                <MdLocationOn style={{ fontSize: '30' }} />
              )}
            </ButtonLink>
          );
        },
      },
      {
        title: this.props.t('citizens.list.table.license-plates'),
        dataIndex: 'licensePlates',
        key: 'licensePlates',
        align: 'center',
        render: (text, record) => {
          return (
            <ButtonLink
              color="black"
              onClick={() =>
                this.props.history.push({
                  pathname: '/admin-license-plates',
                  state: { username: record.username, userId: record.id },
                })
              }
            >
              {record.pendingWhitelistEntries !== 0 ? (
                <Tooltip
                  title={this.props.t('citizens.list.table.pending-tooltip')}
                  placement="rightTop"
                  color={colors.blue.main}
                >
                  <Badge
                    size="small"
                    offset={[-8, 4]}
                    status="success"
                    count={record.pendingWhitelistEntries}
                    color="orange"
                  >
                    <MdDirectionsCar style={{ fontSize: '30' }} />
                  </Badge>
                </Tooltip>
              ) : (
                <MdDirectionsCar style={{ fontSize: '30' }} />
              )}
            </ButtonLink>
          );
        },
      },
      {
        title: this.props.t('citizens.list.table.actions.title'),
        dataIndex: 'acciones',
        align: 'center',
        render: (text, record) => {
          return (
            <Flex
              justify="center"
              align="center"
            >
              <ButtonLink
                color="blue"
                onClick={() => {
                  Modal.confirm({
                    title: this.props.t('citizens.list.table.actions.reset-title'),
                    icon: <ExclamationCircleFilled />,
                    content: this.props.t('citizens.list.table.actions.reset-description'),
                    centered: true,
                    cancelText: this.props.t('citizens.list.table.actions.cancel'),
                    okText: this.props.t('citizens.list.table.actions.ok'),
                    okButtonProps: {
                      className: 'button-primary-props-blue',
                      shape: 'round',
                    },
                    cancelButtonProps: {
                      className: 'button-primary-props-white',
                      shape: 'round',
                    },
                    onOk: () => {
                      UserRepository.resetCredentials(localStorage.getItem('center'), record.id)
                        .then(() => {
                          notification['success']({
                            message: 'ZinkinData Portal',
                            description: this.props.t('citizens.list.table.actions.success-message'),
                          });
                        })
                        .catch(() => {
                          notification['error']({
                            message: 'ZinkinData Portal',
                            description: this.props.t('citizens.list.table.actions.error-message'),
                          });
                        });
                    },
                  });
                }}
              >
                <Tooltip
                  color={colors.blue.main}
                  title={this.props.t('citizens.list.table.actions.reset')}
                >
                  <MdLockReset style={{ fontSize: '30' }} />
                </Tooltip>
              </ButtonLink>
              <ButtonLink
                color="grey"
                onClick={() => {
                  this.props.history.push({
                    pathname: '/users-edit',
                    state: {
                      username: record.username,
                      name: record.name,
                      surnames: record.surnames,
                      email: record.email,
                      phone: record.phone,
                      reducedMobility: record.reducedMobility,
                    },
                  });
                }}
              >
                <Tooltip
                  color={colors.blue.main}
                  title={this.props.t('citizens.list.table.actions.edit')}
                >
                  <TbEdit style={{ fontSize: '30' }} />
                </Tooltip>
              </ButtonLink>

              {this.props.currentUser.role === 'ROLE_SUPERADMIN' && (
                <ButtonLink
                  color="black"
                  onClick={() => {
                    this.warningWithConfirmAndCancelMessage(record.username);
                  }}
                  type={'primary'}
                >
                  <Tooltip
                    color={colors.blue.main}
                    title={this.props.t('citizens.list.table.actions.delete')}
                  >
                    <TbTrashXFilled style={{ fontSize: '30' }} />
                  </Tooltip>
                </ButtonLink>
              )}
            </Flex>
          );
        },
      },
    ];
    this.state = {
      loading: true,
      filters: null,
      licensePlate: undefined,
      data: [],
      zonesAuthoritzations: [],
      zoneError: null,
      modalVisible: false,
      resetButton: true,
      searchDni: '',
      searchName: '',
      searchSurname: '',
      searchPendingZones: false,
      searchPendingPlates: false,

      totalCitizens: 0,
    };

    this.updateData = this.updateData.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);
  }

  applyFilters = () => {
    const params = new URLSearchParams();

    if (this.state.filters.dni) {
      params.set('dni', this.state.filters.dni);
    }
    if (this.state.filters.licensePlate) {
      params.set('licensePlate', this.state.filters.licensePlate);
    }
    if (this.state.filters.name) {
      params.set('name', this.state.filters.name);
    }
    if (this.state.filters.surname) {
      params.set('surname', this.state.filters.surname);
    }
    if (this.state.filters.pendingZones) {
      params.set('pendingZones', this.state.filters.pendingZones);
    }
    if (this.state.filters.pendingPlates) {
      params.set('pendingPlates', this.state.filters.pendingPlates);
    }

    this.props.history.push(`?${params.toString()}`);
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  componentWillMount() {
    UserRepository.getCitizenCount().then((response) => {
      this.setState({ totalCitizens: response.total });
    });

    const params = new URLSearchParams(this.props.location.search);
    const dni = params.get('dni') || '';
    const licensePlate = params.get('licensePlate') || '';
    const name = params.get('name') || '';
    const surname = params.get('surname') || '';
    const pendingZones = params.get('pendingZones') || '';
    const pendingPlates = params.get('pendingPlates') || '';

    this.setState(
      {
        filters: {
          dni,
          licensePlate,
          name,
          surname,
          pendingZones,
          pendingPlates,
        },
        searchDni: dni,
        licensePlate: licensePlate,
        searchName: name,
        searchSurname: surname,
        searchPendingZones: pendingZones,
        searchPendingPlates: pendingPlates,
      },
      () => {
        this.updateData(); // This callback will be called after the state has been updated
      }
    );
  }

  async updateData() {
    this.setState({ loading: true });

    const { licensePlate, searchDni, searchName, searchSurname, searchPendingZones, searchPendingPlates } = this.state;

    const filters = {
      licensePlate: licensePlate || undefined,
      searchDni: searchDni || undefined,
      searchName: searchName || undefined,
      searchSurname: searchSurname || undefined,
      searchPendingZones: searchPendingZones || false,
      searchPendingPlates: searchPendingPlates || false,
    };

    try {
      const response = await UserRepository.getUsersFromCenter(
        localStorage.getItem('center'),
        filters.licensePlate,
        filters.searchDni,
        filters.searchName,
        filters.searchSurname,
        filters.searchPendingPlates,
        filters.searchPendingZones
      );

      this.setState({
        loading: false,
        data: response.map((user, key) => ({
          key,
          id: user.userId,
          username: user.username,
          name: user.name,
          surnames: user.surnames,
          email: user.email,
          phone: user.phone,
          reducedMobility: user.reducedMobility,
          pendingZoneAuthorizations: user.pendingZoneAuthorizations,
          pendingWhitelistEntries: user.pendingWhitelistEntries,
          enable: user.enable,
          verified: user.verified,
        })),
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async searchByLicensePlate() {
    this.setState({ loading: true });

    var licensePlate = undefined;
    var name = undefined;
    var surname = undefined;
    var dni = undefined;

    if (this.state.licensePlate !== '' && this.state.licensePlate !== null) licensePlate = this.state.licensePlate;

    if (this.state.searchDni !== '' && this.state.searchDni !== null) dni = this.state.searchDni;

    if (this.state.searchName !== '' && this.state.searchName !== null) name = this.state.searchName;

    if (this.state.searchSurname !== '' && this.state.searchSurname !== null) surname = this.state.searchSurname;

    try {
      const response = await UserRepository.getUsersFromCenter(
        localStorage.getItem('center'),
        licensePlate,
        dni,
        name,
        surname,
        this.state.searchPendingPlates,
        this.state.searchPendingZones
      );
      this.setState({
        loading: false,
        data: response.map((prop, key) => {
          return {
            key: key,
            id: prop.userId,
            username: prop.username,
            name: prop.name,
            surnames: prop.surnames,
            email: prop.email,
            phone: prop.phone,
            reducedMobility: prop.reducedMobility,
            pendingZoneAuthorizations: prop.pendingZoneAuthorizations,
            pendingWhitelistEntries: prop.pendingWhitelistEntries,
            enable: prop.enable,
            verified: prop.verified,
          };
        }),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async deleteUser(username) {
    try {
      await UserRepository.deleteUserByUsername(username);
      this.hideAlert();
      notification['success']({
        message: 'ZinkinData Portal',
        description: this.props.t('citizens.list.delete.success'),
      });
      this.updateData();
    } catch (error) {
      notification['error']({
        message: 'ZinkinData Portal',
        description: this.props.t('citizens.list.delete.error'),
      });
    }
  }

  warningWithConfirmAndCancelMessage(username) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('citizens.list.delete.modal.title')}
          onConfirm={() => this.successDelete(username)}
          onCancel={() => this.cancelDelete(this.props.t('citizens.list.delete.modal.cancel-message'))}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText={this.props.t('citizens.list.delete.modal.confirm-button')}
          cancelBtnText={this.props.t('citizens.list.delete.modal.cancel-button')}
          showCancel
        />
      ),
    });
  }

  successDelete(username) {
    this.deleteUser(username);
  }

  cancelDelete() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block' }}
          title={this.props.t('citizens.list.delete.cancel-modal.title')}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.props.t('citizens.list.delete.cancel-modal.message')}
        </SweetAlert>
      ),
    });
  }
  async resetForm() {
    await this.resetState();
    this.searchByLicensePlate();
  }

  resetState() {
    return new Promise((resolve) => {
      this.setState({
        searchDni: undefined,
        searchName: undefined,
        searchSurname: undefined,
        licensePlate: undefined,
        resetButton: true,
        searchPendingPlates: false,
        searchPendingZones: false,
      });
      resolve();
    });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  render() {
    if (this.props.currentUser.role !== 'ROLE_ADMIN' && this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return <AccessDenied />;
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      return (
        <div className="main-content">
          {this.state.alert}
          <Breadcrumb
            separator=">"
            style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
          >
            <Breadcrumb.Item>
              <div style={{ display: 'inline-block', height: '30px' }}>
                <i style={{ lineHeight: '30px', verticalAlign: 'middle', marginRight: '5px', fontSize: '30px' }}>
                  <MdPeople />
                </i>
                {this.props.t('sidebar.citizen')} <b>({this.state.totalCitizens})</b>
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Spin
            spinning={this.state.loading}
            indicator={antIcon}
          >
            <AntCard>
              <AntRow
                gutter={[16, 16]}
                align="middle"
                style={{ marginBottom: '10px' }}
              >
                <AntCol
                  xs={{ span: 12, order: 2 }}
                  sm={{ span: 12, order: 2 }}
                  md={{ span: 12, order: 2 }}
                  lg={{ span: 12, order: 2 }}
                  xl={{ span: 4, order: 1 }}
                  xxl={{ span: 4, order: 1 }}
                >
                  <Input
                    size="large"
                    placeholder={this.props.t('citizens.list.filters.dni')}
                    name="dni"
                    value={this.state.searchDni}
                    onChange={(event) => {
                      this.setState({
                        searchDni: event.target.value,
                        filters: { ...this.state.filters, dni: event.target.value },
                      });
                      this.setState({ resetButton: false });
                    }}
                  />
                </AntCol>
                <AntCol
                  xs={{ span: 12, order: 3 }}
                  sm={{ span: 12, order: 3 }}
                  md={{ span: 12, order: 3 }}
                  lg={{ span: 12, order: 3 }}
                  xl={{ span: 4, order: 2 }}
                  xxl={{ span: 4, order: 2 }}
                >
                  <Input
                    size="large"
                    placeholder={this.props.t('citizens.list.filters.license-plate')}
                    name="licensePlate"
                    value={this.state.licensePlate}
                    onChange={(event) => {
                      this.setState({
                        licensePlate: event.target.value,
                        filters: { ...this.state.filters, licensePlate: event.target.value },
                      });
                      this.setState({ resetButton: false });
                    }}
                  />
                </AntCol>
                <AntCol
                  xs={{ span: 24, order: 5 }}
                  sm={{ span: 12, order: 5 }}
                  md={{ span: 12, order: 5 }}
                  lg={{ span: 12, order: 5 }}
                  xl={{ span: 3, order: 3 }}
                  xxl={{ span: 3, order: 3 }}
                >
                  <ButtonPrimary
                    onClick={() => {
                      this.searchByLicensePlate();
                      this.applyFilters();
                    }}
                    size="large"
                    shape="round"
                    disabled={this.state.searchButton}
                    style={{ width: '100%' }}
                  >
                    {this.props.t('citizens.list.filters.search-button')}
                  </ButtonPrimary>
                </AntCol>
                <AntCol
                  xs={{ span: 24, order: 6 }}
                  sm={{ span: 12, order: 6 }}
                  md={{ span: 12, order: 6 }}
                  lg={{ span: 12, order: 6 }}
                  xl={{ span: 3, order: 4 }}
                  xxl={{ span: 3, order: 4 }}
                >
                  <ButtonPrimary
                    style={{ width: '100%' }}
                    onClick={() => this.resetForm()}
                    disabled={this.state.resetButton}
                    color="black"
                    size="large"
                    shape="round"
                  >
                    {this.props.t('citizens.list.filters.reset-button')}
                  </ButtonPrimary>
                </AntCol>
                <AntCol
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 24, order: 1 }}
                  lg={{ span: 24, order: 1 }}
                  xl={{ span: 4, offset: 6, order: 5 }}
                  xxl={{ span: 4, offset: 6, order: 5 }}
                >
                  <ButtonPrimary
                    size="large"
                    shape="round"
                    style={{ width: '100%', marginRight: '10px' }}
                    onClick={() => this.props.history.push('/users-add')}
                  >
                    {this.props.t('citizens.list.add-button')}
                    <MdAdd
                      style={{
                        verticalAlign: 'sub',
                        fontSize: '20',
                        marginLeft: '5px',
                      }}
                    />
                  </ButtonPrimary>
                </AntCol>
                <AntCol
                  xs={{ span: 24, order: 4 }}
                  sm={{ span: 24, order: 4 }}
                  md={{ span: 24, order: 4 }}
                  lg={{ span: 24, order: 4 }}
                  xl={{ span: 14, order: 6 }}
                  xxl={{ span: 14, order: 6 }}
                >
                  <Collapse
                    ghost
                    items={[
                      {
                        key: '1',
                        label: this.props.t('citizens.list.filters.more-filters'),
                        children: (
                          <AntRow
                            gutter={[16, 16]}
                            align="middle"
                            style={{ marginBottom: '10px' }}
                          >
                            <AntCol
                              xs={{ span: 24 }}
                              sm={{ span: 12 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                              xl={{ span: 12 }}
                              xxl={{ span: 6 }}
                            >
                              <Input
                                size="large"
                                placeholder={this.props.t('citizens.list.filters.name')}
                                name="name"
                                value={this.state.searchName}
                                onChange={(event) => {
                                  this.setState({
                                    searchName: event.target.value,
                                    filters: { ...this.state.filters, name: event.target.value },
                                  });
                                  this.setState({ resetButton: false });
                                }}
                              />
                            </AntCol>
                            <AntCol
                              xs={{ span: 24 }}
                              sm={{ span: 12 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                              xl={{ span: 12 }}
                              xxl={{ span: 6 }}
                            >
                              <Input
                                size="large"
                                placeholder={this.props.t('citizens.list.filters.surname')}
                                name="surname"
                                value={this.state.searchSurname}
                                onChange={(event) => {
                                  this.setState({
                                    searchSurname: event.target.value,
                                    filters: { ...this.state.filters, surname: event.target.value },
                                  });
                                  this.setState({ resetButton: false });
                                }}
                              />
                            </AntCol>
                            <AntCol
                              xs={{ span: 24 }}
                              sm={{ span: 12 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                              xl={{ span: 12 }}
                              xxl={{ span: 6 }}
                            >
                              <Flex
                                align="center"
                                justify="center"
                                gap="small"
                              >
                                <Typography.Text strong>
                                  {this.props.t('citizens.list.filters.pending-zones')}
                                </Typography.Text>
                                <Switch
                                  checked={this.state.searchPendingZones}
                                  onChange={(e) => {
                                    this.setState({
                                      searchPendingZones: e,
                                      resetButton: false,
                                      filters: { ...this.state.filters, pendingZones: e },
                                    });
                                  }}
                                />
                              </Flex>
                            </AntCol>
                            <AntCol
                              xs={{ span: 24 }}
                              sm={{ span: 12 }}
                              md={{ span: 12 }}
                              lg={{ span: 12 }}
                              xl={{ span: 12 }}
                              xxl={{ span: 6 }}
                            >
                              <Flex
                                align="center"
                                justify="center"
                                gap="small"
                              >
                                <Typography.Text strong>
                                  {this.props.t('citizens.list.filters.pending-plates')}
                                </Typography.Text>
                                <Switch
                                  checked={this.state.searchPendingPlates}
                                  onChange={(e) => {
                                    this.setState({
                                      searchPendingPlates: e,
                                      resetButton: false,
                                      filters: { ...this.state.filters, pendingPlates: e },
                                    });
                                  }}
                                />
                              </Flex>
                            </AntCol>
                          </AntRow>
                        ),
                      },
                    ]}
                  />
                </AntCol>
              </AntRow>
              <Table
                dataSource={this.state.data}
                columns={this.columns}
              />
            </AntCard>
          </Spin>
        </div>
      );
    }
  }
}

export default withTranslation('common')(UserList);
