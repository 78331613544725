import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import Icon from '@ant-design/icons';

import { notification, Spin, Form, Input, Breadcrumb, Select } from 'antd';
import Card from 'components/Card.jsx';

import * as UserRepository from '../../repository/user/UserRepository';
import { MdPeople } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

const { Option } = Select;
class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: '',
      surnames: '',
      email: '',
      phone: undefined,

      reducedMobility: 'No',

      nameError: null,
      surnamesError: null,
      emailError: null,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSurnamesChange = this.handleSurnamesChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  componentDidMount() {
    try {
      this.setState({
        username: this.props.location.state.username,
        name: this.props.location.state.name !== null ? this.props.location.state.name : undefined,
        surnames: this.props.location.state.surnames !== null ? this.props.location.state.surnames : undefined,
        email: this.props.location.state.email !== null ? this.props.location.state.email : undefined,
        phone: this.props.location.state.phone !== null ? this.props.location.state.phone : undefined,
        reducedMobility: this.props.location.state.reducedMobility
          ? this.props.t('citizens.create-edit.form.yes')
          : this.props.t('citizens.create-edit.form.no'),
      });
    } catch (error) {
      this.props.history.push('/users');
    }
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });

    if (e.target.value === '' || e.target.value === undefined) {
      this.setState({ nameError: null });
    } else if (e.target.value.length < 3) {
      this.setState({
        nameError: <small className="text-danger">{this.props.t('citizens.create-edit.form.errors.min-name')}</small>,
      });
    } else {
      this.setState({ nameError: null });
    }
  }

  handleSurnamesChange(e) {
    this.setState({ surnames: e.target.value });

    if (e.target.value === '' || e.target.value === undefined) {
      this.setState({ surnamesError: null });
    } else if (e.target.value.length < 3) {
      this.setState({
        surnamesError: (
          <small className="text-danger">{this.props.t('citizens.create-edit.form.errors.min-name')}</small>
        ),
      });
    } else {
      this.setState({ surnamesError: null });
    }
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });

    if (e.target.value === '' || e.target.value === undefined) {
      this.setState({
        emailError: (
          <small className="text-danger">{this.props.t('citizens.create-edit.form.errors.required-email')}</small>
        ),
      });
    } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
      this.setState({ emailError: null });
    } else {
      this.setState({
        emailError: (
          <small className="text-danger">{this.props.t('citizens.create-edit.form.errors.pattern-email')}</small>
        ),
      });
    }
  }

  async editUser() {
    this.setState({ loading: true });
    var username = this.state.username;
    var name = this.state.name;
    var surnames = this.state.surnames;
    var email = this.state.email;
    var phone = this.state.phone;
    var reducedMobility = this.state.reducedMobility === 'Si';
    var error = false;

    if (surnames === '' || surnames === null) surnames = undefined;

    if (email === '' || email === null) {
      this.setState({
        emailError: (
          <small className="text-danger">{this.props.t('citizens.create-edit.form.errors.required-email')}</small>
        ),
      });

      error = true;
    }

    if (phone === '' || phone === 0 || phone === null) phone = undefined;

    if (name === '' || name === undefined) {
      this.setState({
        nameError: (
          <small className="text-danger">{this.props.t('citizens.create-edit.form.errors.required-name')}</small>
        ),
      });

      error = true;
    }

    if (this.state.nameError !== null || this.state.surnamesError !== null || this.state.emailError !== null || error) {
      notification['error']({
        message: 'ZinkinData Portal',
        description: this.props.t('citizens.create-edit.error-edit'),
      });
    } else {
      try {
        await UserRepository.editUser(username, name, surnames, email, phone, reducedMobility);
        notification['success']({
          message: 'ZinkinData Portal',
          description: this.props.t('citizens.create-edit.success-edit'),
        });
        this.props.history.push('/users');
      } catch (error) {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      }
    }

    this.setState({ loading: false });
  }

  render() {
    const error_validation_password_message = this.props.t('profile.password.password-pattern');
    const confirm_password_message = this.props.t('profile.password.confirm-password-error');
    const changePassword = (values) => {
      UserRepository.adminUpdatePassword(this.state.username, values.password)
        .then(() => {
          this.setState({ loading: false });
          notification['success']({
            message: 'LPRConnect App',
            description: this.props.t('profile.update.success'),
          });
          this.props.history.push('/users');
        })
        .catch(() => {
          this.setState({ loading: false });
          notification['error']({
            message: 'LPRConnect App',
            description: this.props.t('profile.update.internal-error'),
          });
        });
    };

    if (this.props.currentUser.role !== 'ROLE_ADMIN' && this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <Icon
          type="loading"
          style={{ fontSize: 24 }}
          spin
        />
      );
      return (
        <div className="main-content">
          <Grid fluid>
            <Spin
              spinning={this.state.loading}
              indicator={antIcon}
            >
              <Breadcrumb
                separator=">"
                style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
              >
                <Breadcrumb.Item
                  className="menu-history"
                  onClick={() => this.props.history.push('/users')}
                >
                  <div style={{ display: 'inline-block', height: '30px' }}>
                    <i style={{ lineHeight: '30px', verticalAlign: 'middle', marginRight: '5px', fontSize: '30px' }}>
                      <MdPeople />
                    </i>
                    {this.props.t('sidebar.citizen')}
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{this.props.t('citizens.create-edit.edit-breadcrumb')}</Breadcrumb.Item>
              </Breadcrumb>

              <Row>
                <Col md={7}>
                  <Card
                    content={
                      <form>
                        <legend>
                          {this.props.t('citizens.create-edit.edit-breadcrumb')}: {this.state.username}
                        </legend>
                        <Row>
                          <Col md={4}>
                            <FormGroup>
                              <ControlLabel>
                                {this.props.t('citizens.create-edit.form.name')}
                                <span style={{ color: 'red', fontWeight: 'bold' }}> *</span>
                              </ControlLabel>
                              <FormControl
                                placeholder="Ex: Carles"
                                type="text"
                                value={this.state.name}
                                onChange={(event) => this.handleNameChange(event)}
                              />
                              {this.state.nameError}
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <ControlLabel>{this.props.t('citizens.create-edit.form.surnames')}</ControlLabel>
                              <FormControl
                                placeholder="Ex: Martí Pérez"
                                type="text"
                                value={this.state.surnames}
                                onChange={(event) => this.handleSurnamesChange(event)}
                              />
                              {this.state.surnamesError}
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <ControlLabel>
                                {this.props.t('citizens.create-edit.form.reduced-mobility')}
                                <span style={{ color: 'red', fontWeight: 'bold' }}> *</span>
                              </ControlLabel>
                              <Select
                                size="large"
                                value={this.state.reducedMobility}
                                onChange={(event) => this.setState({ reducedMobility: event })}
                              >
                                <Option key={'Si'}>{this.props.t('citizens.create-edit.form.yes')}</Option>
                                <Option key={'No'}>{this.props.t('citizens.create-edit.form.no')}</Option>
                              </Select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>
                                {this.props.t('citizens.create-edit.form.email')}
                                <span style={{ color: 'red', fontWeight: 'bold' }}> *</span>
                              </ControlLabel>
                              <FormControl
                                placeholder="Ex: cmartiperez@gmail.com"
                                type="text"
                                value={this.state.email}
                                onChange={(event) => this.handleEmailChange(event)}
                              />
                              {this.state.emailError}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>{this.props.t('citizens.create-edit.form.phone')}</ControlLabel>
                              <FormControl
                                placeholder="Ex: 935656981"
                                type="number"
                                value={this.state.phone}
                                min={0}
                                onChange={(event) =>
                                  event.target.value < 0
                                    ? this.setState({ phone: 0 })
                                    : this.setState({ phone: event.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <ButtonPrimary
                          color="blue"
                          size="large"
                          shape="round"
                          onClick={() => this.editUser()}
                        >
                          {this.props.t('citizens.create-edit.save-button')}
                        </ButtonPrimary>
                      </form>
                    }
                  />
                </Col>
                <Col md={5}>
                  <Card
                    content={
                      <Form
                        layout="vertical"
                        onFinish={changePassword}
                      >
                        <legend style={{ color: '#333', fontSize: '21px' }}>
                          {this.props.t('profile.password.title')}
                        </legend>

                        <Form.Item
                          name="password"
                          label={this.props.t('profile.password.new')}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: this.props.t('profile.password.password-required'),
                            },
                            () => ({
                              validator(_, value) {
                                if (value.length < 6) {
                                  return Promise.reject(new Error(error_validation_password_message));
                                }
                                const patron = new RegExp(
                                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-_=+#~])[A-Za-z\\d@$!%*?&\\-_=+#~]+$'
                                );
                                if (!patron.test(value)) {
                                  return Promise.reject(new Error(error_validation_password_message));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <Form.Item
                          name="confirm"
                          label={this.props.t('profile.password.confirm')}
                          dependencies={['password']}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: this.props.t('profile.password.password-required'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(confirm_password_message));
                              },
                            }),
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                        <ButtonPrimary
                          color="black"
                          shape="round"
                          size="large"
                          htmlType="submit"
                          style={{ width: '100%' }}
                        >
                          {this.props.t('profile.password.update')}
                        </ButtonPrimary>
                      </Form>
                    }
                  />
                </Col>
              </Row>
            </Spin>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(UserEdit);
