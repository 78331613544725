import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Spin, notification, Breadcrumb, Input, Form, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

import { v4 as uuid4 } from 'uuid';

import * as UserRepository from '../../repository/user/UserRepository.js';
import { RiShieldUserLine } from 'react-icons/ri';

class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    if (this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      const createAdmin = (values) => {
        UserRepository.createAdminUser(
          uuid4(),
          values.dni,
          values.name,
          values.surnames,
          values.email,
          localStorage.getItem('center')
        )
          .then((response) => {
            notification['success']({
              message: 'ZinkinData Portal',
              description: this.props.t('admin-users.create-edit.success-message'),
            });

            this.props.history.push('/admin-users');

            this.setState({ loading: false });
          })
          .catch(() => {
            notification['error']({
              message: 'ZinkinData Portal',
              description: this.props.t('admin-users.create-edit.exist-message'),
            });

            this.setState({ loading: false });
          });
      };
      return (
        <div className="main-content">
          <Grid fluid>
            <Breadcrumb
              separator=">"
              style={{ marginBottom: '10px', fontSize: 20 }}
            >
              <Breadcrumb.Item
                className="menu-history"
                onClick={() => this.props.history.push('/admin-users')}
              >
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i
                    style={{
                      lineHeight: '30px',
                      verticalAlign: 'middle',
                      marginRight: '5px',
                      fontSize: '30px',
                    }}
                  >
                    <RiShieldUserLine />
                  </i>
                  {this.props.t('sidebar.admin-users')}
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{this.props.t('admin-users.create-edit.title')}</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Card>
                    <Form
                      layout="vertical"
                      onFinish={createAdmin}
                    >
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Form.Item
                          name={'dni'}
                          label={this.props.t('admin-users.create-edit.form.dni')}
                          rules={[
                            {
                              required: true,
                              message: this.props.t('admin-users.create-edit.form.errors.required-dni'),
                            },
                            {
                              min: 4,
                              message: this.props.t('admin-users.create-edit.form.errors.min-dni'),
                            },
                          ]}
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                          }}
                        >
                          <Input placeholder="Ex: 75382654H" />
                        </Form.Item>

                        <Form.Item
                          name={'name'}
                          label={this.props.t('admin-users.create-edit.form.name')}
                          rules={[
                            {
                              required: true,
                              message: this.props.t('admin-users.create-edit.form.errors.required-name'),
                            },
                            {
                              min: 3,
                              message: this.props.t('admin-users.create-edit.form.errors.min-name'),
                            },
                          ]}
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                            margin: '0 8px',
                          }}
                        >
                          <Input placeholder="Ex: Carles" />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Form.Item
                          name={'surnames'}
                          label={this.props.t('admin-users.create-edit.form.surnames')}
                          initialValue={''}
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                          }}
                        >
                          <Input placeholder="Ex: Martí Pérez" />
                        </Form.Item>

                        <Form.Item
                          name={'email'}
                          label={this.props.t('admin-users.create-edit.form.email')}
                          rules={[
                            {
                              required: true,
                              message: this.props.t('admin-users.create-edit.form.errors.required-email'),
                            },
                            {
                              pattern: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
                              message: this.props.t('admin-users.create-edit.form.errors.pattern-email'),
                            },
                          ]}
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                            margin: '0 8px',
                          }}
                        >
                          <Input placeholder="Ex: cmartiperez@gmail.com" />
                        </Form.Item>
                      </Form.Item>
                      <ButtonPrimary
                        color="blue"
                        shape="round"
                        size="large"
                        htmlType="submit"
                      >
                        {this.props.t('admin-users.create-edit.add-button')}
                      </ButtonPrimary>
                    </Form>
                  </Card>
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(UserCreate);
