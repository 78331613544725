import { API_BASE_URL, ACCESS_TOKEN, buildOptionsWithAuth } from './Constants';

const request = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then((response) =>
      response.ok
        ? response.json().then((json) => {
            if (!response.ok) {
              return Promise.reject(json);
            }
            return json;
          })
        : response.status === 409
        ? response.text().then((json) => Promise.reject(json))
        : response
    )
    .catch((error) => {
      return Promise.reject(error);
    });
};

// WHITELIST ENTRIES
export function getAllWhiteListFromUser() {
  return request({
    url: API_BASE_URL + '/whitelist-entries?center-name=' + localStorage.getItem('center'),
    method: 'GET',
  });
}

export function getWhitelistEntriesFromUser(username) {
  return request({
    url: API_BASE_URL + '/admin/whitelist-entries/' + username,
    method: 'GET',
  });
}

// CENTERS

export function getAllZonesFromCenter(name) {
  return request({
    url: API_BASE_URL + '/centers/' + name + '/zones',
    method: 'GET',
  });
}

export function getAllZonesIlla(centername) {
  return request({
    url: API_BASE_URL + '/centers/' + centername + '/zar/zones',
    method: 'GET',
  });
}

// AUTHORIZED ZONES
export function getZoneAuthorizationsByUser() {
  return request({
    url: API_BASE_URL + '/zone-authorizations?center=' + localStorage.getItem('center'),
    method: 'GET',
  });
}

export async function getZoneAuthorizationsByUsername(username) {
  return request({
    url: API_BASE_URL + '/admin/zone-authorizations/' + username + '?center=' + localStorage.getItem('center'),
    method: 'GET',
  });
}

export function createZoneAuthorization(id, zoneId, reason, username) {
  var json = {
    id: id,
    zoneId: zoneId,
    username: username,
    reason: reason,
  };

  const headers = buildOptionsWithAuth('POST');
  const url = `${API_BASE_URL}/zone-authorizations/${localStorage.getItem('center')}`;

  return fetch(url, { ...headers, body: JSON.stringify(json) }).then((response) => {
    if (response.status !== 201) throw new Error('Error intern de servidor');
  });
}

export function deleteZoneAuthorization(zoneId, username, deleteRequest) {
  return request({
    url:
      API_BASE_URL +
      '/admin/zone-authorizations/' +
      zoneId +
      '?username=' +
      username +
      '&center=' +
      localStorage.getItem('center'),
    method: 'DELETE',
    body: JSON.stringify(deleteRequest),
  });
}

export function getCenterEntitiesByCenter(centername) {
  return request({
    url: API_BASE_URL + '/admin/center-entities?center=' + centername,
    method: 'GET',
  });
}
