import React, { Component } from 'react';
import Card from 'components/Card.jsx';
import { Grid, Row, Col } from 'react-bootstrap';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Table, Breadcrumb, Tooltip } from 'antd';
import AddEmail from 'components/AddEmail.jsx';

import { getAllZonesIlla, getZoneAuthorizationsByUser } from 'util/APIUtils';
import { MdAdd, MdLocationOn } from 'react-icons/md';
import { FaCircle } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import moment from 'moment';
import colors from 'components/Buttons/colors';

class CitizenZoneAuthoritzationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centername: localStorage.getItem('center'),
      username: this.props.currentUser.username,
      role: this.props.currentUser.role,
      loading: true,
    };

    this.columns = [
      {
        title: this.props.t('zone-authorizations.citizen.list.table.status'),
        dataIndex: 'validity',
        key: 'validity',
        align: 'center',
        render: (_, record) => {
          const validityTags = {
            APPROVED: (
              <Tooltip
                color={colors.blue.main}
                placement="top"
                title={this.props.t('zone-authorizations.validity.approved')}
              >
                <FaCircle style={{ color: '#28a745' }} />
              </Tooltip>
            ),
            PENDING: (
              <Tooltip
                placement="top"
                title={this.props.t('zone-authorizations.validity.pending')}
                color={colors.blue.main}
              >
                <FaCircle style={{ color: 'orange' }} />
              </Tooltip>
            ),
            DENIED: (
              <Tooltip
                placement="top"
                title={this.props.t('zone-authorizations.validity.denied')}
                color={colors.blue.main}
              >
                <FaCircle style={{ color: 'red' }} />
              </Tooltip>
            ),
          };

          return validityTags[record.validity] || <div></div>;
        },
      },
      {
        title: this.props.t('zone-authorizations.citizen.list.table.zone'),
        dataIndex: 'zoneName',
        key: 'zoneName',
      },
      {
        title: this.props.t('zone-authorizations.citizen.list.table.created-at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
        defaultSortOrder: 'descend',
        render: (text, record) => {
          if (record.createdAt !== undefined && record.createdAt !== null)
            return moment(record.createdAt).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('zone-authorizations.citizen.list.table.reason'),
        dataIndex: 'reason',
      },
    ];
  }

  componentWillMount() {
    this.getZoneAuthorizationsFromUser();
  }

  getZoneAuthorizationsFromUser() {
    getZoneAuthorizationsByUser(this.state.username)
      .then((zoneAuthorizationsResponse) => {
        getAllZonesIlla(this.state.centername)
          .then((zonesIllaResponse) => {
            let zoneAuthorizations = zoneAuthorizationsResponse.map((zoneAuthorization) => {
              let zoneName = '';
              zonesIllaResponse.forEach((zone) => {
                if (zone.id === zoneAuthorization.zoneId) zoneName = zone.name;
              });

              return {
                zoneName,
                ...zoneAuthorization,
              };
            });

            this.setState({
              zoneAuthorizations,
              loading: false,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  render() {
    if (
      this.props.currentUser.role !== 'ROLE_ADMIN' &&
      this.props.currentUser.role !== 'ROLE_SUPERADMIN' &&
      this.props.currentUser.role !== 'ROLE_USER'
    ) {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      return (
        <div className="main-content">
          <Grid fluid>
            {this.props.currentUser.email === '' ||
            this.props.currentUser.email === null ||
            this.props.currentUser.email === undefined ? (
              <AddEmail {...this.props} />
            ) : null}

            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Breadcrumb
                    separator=">"
                    style={{
                      marginBottom: '10px',
                      marginTop: '10px',
                      fontSize: 20,
                      lineHeight: '50px',
                    }}
                  >
                    <Breadcrumb.Item>
                      <div style={{ display: 'inline-block', height: '30px' }}>
                        <i
                          style={{
                            lineHeight: '30px',
                            verticalAlign: 'middle',
                            marginRight: '5px',
                            fontSize: '30px',
                          }}
                        >
                          <MdLocationOn />
                        </i>
                        {this.props.t('sidebar.zone-authorizations')}
                      </div>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <Card
                    content={
                      <div>
                        <legend>
                          {this.props.t('zone-authorizations.citizen.list.title')}
                          {this.state.username}
                        </legend>
                        {localStorage.getItem('center').toUpperCase() === 'VIC' && (
                          <ButtonPrimary
                            color="blue"
                            shape="round"
                            size="large"
                            style={{
                              margin: '10px 0 20px 0',
                            }}
                            onClick={() =>
                              this.props.history.push({
                                pathname: '/formulari-acces',
                                state: { initialStep: 1, singlePage: true, username: this.state.username },
                              })
                            }
                          >
                            Solicitar accés a una nova zona
                            <MdAdd
                              style={{
                                verticalAlign: 'sub',
                                fontSize: '20',
                                marginLeft: '5px',
                              }}
                            />
                          </ButtonPrimary>
                        )}
                        <Table
                          dataSource={this.state.zoneAuthorizations}
                          columns={this.columns}
                          rowKey={(record) => record.zoneId}
                          pagination={{ pageSize: 5, showSizeChanger: false }}
                        />
                      </div>
                    }
                  />
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(CitizenZoneAuthoritzationsList);
