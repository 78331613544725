import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { notification, Spin, Form, Input, Breadcrumb } from 'antd';
import Icon from '@ant-design/icons';
import Card from 'components/Card.jsx';
import { withTranslation } from 'react-i18next';

import * as UserRepository from '../../repository/user/UserRepository';
import { RiShieldUserLine } from 'react-icons/ri';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,

      username: '',
      name: '',
      surnames: '',
      email: '',

      nameError: null,
      surnamesError: null,
      emailError: null,
    };
  }

  componentDidMount() {
    var { username, name, surnames, email } = this.props.location.state.user;

    this.setState({
      username,
      name,
      surnames,
      email,
      loading: false,
    });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });

    if (e.target.value === '' || e.target.value === undefined) {
      this.setState({ nameError: null });
    } else if (e.target.value.length < 3) {
      this.setState({
        nameError: (
          <small className="text-danger">{this.props.t('admin-users.create-edit.form.errors.min-name')}</small>
        ),
      });
    } else {
      this.setState({ nameError: null });
    }
  }

  handleSurnamesChange(e) {
    this.setState({ surnames: e.target.value });

    if (e.target.value === '' || e.target.value === undefined) {
      this.setState({ surnamesError: null });
    } else if (e.target.value.length < 3) {
      this.setState({
        surnamesError: (
          <small className="text-danger">{this.props.t('admin-users.create-edit.form.errors.min-surname')}</small>
        ),
      });
    } else {
      this.setState({ surnamesError: null });
    }
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });

    if (e.target.value === '' || e.target.value === undefined) {
      this.setState({
        emailError: (
          <small className="text-danger">{this.props.t('admin-users.create-edit.form.errors.required-email')}</small>
        ),
      });
    } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
      this.setState({ emailError: null });
    } else {
      this.setState({
        emailError: (
          <small className="text-danger">{this.props.t('admin-users.create-edit.form.errors.pattern-email')}</small>
        ),
      });
    }
  }

  async editUser() {
    this.setState({ loading: true });
    var { username, name, surnames, email } = this.state;
    var error = false;

    if (surnames === '' || surnames === null) surnames = undefined;

    if (email === '' || email === null) {
      this.setState({
        emailError: (
          <small className="text-danger">{this.props.t('admin-users.create-edit.form.errors.required-email')}</small>
        ),
      });

      error = true;
    }

    if (name === '' || name === undefined) {
      this.setState({
        nameError: (
          <small className="text-danger">{this.props.t('admin-users.create-edit.form.errors.required-name')}</small>
        ),
      });

      error = true;
    }

    if (this.state.nameError !== null || this.state.surnamesError !== null || this.state.emailError !== null || error) {
      notification['error']({
        message: 'ZinkinData Portal',
        description: this.props.t('admin-users.create-edit.error-edit'),
      });
    } else {
      UserRepository.editAdminUser(username, name, surnames, email, localStorage.getItem('center'))
        .then((response) => {
          notification['success']({
            message: 'ZinkinData Portal',
            description: this.props.t('admin-users.create-edit.success-edit'),
          });
          this.props.history.push('/admin-users');
        })
        .catch((error) => {
          notification['error']({
            message: 'ZinkinData Portal',
            description: error.message,
          });
        });
    }

    this.setState({ loading: false });
  }

  render() {
    const error_validation_password_message = this.props.t('profile.password.password-pattern');
    const confirm_password_message = this.props.t('profile.password.confirm-password-error');
    const changePassword = (values) => {
      UserRepository.adminUpdatePassword(this.state.username, values.password)
        .then(() => {
          this.setState({ loading: false });
          notification['success']({
            message: 'LPRConnect App',
            description: this.props.t('profile.update.success'),
          });
          this.props.history.push('/admin-users');
        })
        .catch(() => {
          this.setState({ loading: false });
          notification['error']({
            message: 'LPRConnect App',
            description: this.props.t('profile.update.internal-error'),
          });
        });
    };

    if (this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <Icon
          type="loading"
          style={{ fontSize: 24 }}
          spin
        />
      );
      return (
        <div className="main-content">
          <Grid fluid>
            <Spin
              spinning={this.state.loading}
              indicator={antIcon}
            >
              <Breadcrumb
                separator=">"
                style={{ marginBottom: '10px', fontSize: 20 }}
              >
                <Breadcrumb.Item
                  className="menu-history"
                  onClick={() => this.props.history.push('/admin-users')}
                >
                  <div style={{ display: 'inline-block', height: '30px' }}>
                    <i
                      style={{
                        lineHeight: '30px',
                        verticalAlign: 'middle',
                        marginRight: '5px',
                        fontSize: '30px',
                      }}
                    >
                      <RiShieldUserLine />
                    </i>
                    {this.props.t('sidebar.admin-users')}
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{this.props.t('admin-users.create-edit.edit-title')}</Breadcrumb.Item>
              </Breadcrumb>

              <Row>
                <Col md={7}>
                  <Card
                    content={
                      <form>
                        <legend>
                          {this.props.t('admin-users.create-edit.form.title')}
                          {this.state.username}
                        </legend>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>
                                {this.props.t('admin-users.create-edit.form.name')}
                                <span style={{ color: 'red', fontWeight: 'bold' }}> *</span>
                              </ControlLabel>
                              <FormControl
                                placeholder="Ex: Carles"
                                type="text"
                                value={this.state.name}
                                onChange={(event) => this.handleNameChange(event)}
                              />
                              {this.state.nameError}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>{this.props.t('admin-users.create-edit.form.surnames')}</ControlLabel>
                              <FormControl
                                placeholder="Ex: Martí Pérez"
                                type="text"
                                value={this.state.surnames}
                                onChange={(event) => this.handleSurnamesChange(event)}
                              />
                              {this.state.surnamesError}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <ControlLabel>
                                {this.props.t('admin-users.create-edit.form.email')}
                                <span style={{ color: 'red', fontWeight: 'bold' }}> *</span>
                              </ControlLabel>
                              <FormControl
                                placeholder="Ex: cmartiperez@gmail.com"
                                type="text"
                                value={this.state.email}
                                onChange={(event) => this.handleEmailChange(event)}
                              />
                              {this.state.emailError}
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="generarButton">
                          <ButtonPrimary
                            clor="blue"
                            shape="round"
                            size="large"
                            onClick={() => this.editUser()}
                          >
                            {this.props.t('admin-users.create-edit.save-button')}
                          </ButtonPrimary>
                        </div>
                      </form>
                    }
                  />
                </Col>
                <Col md={5}>
                  <Card
                    content={
                      <Form
                        layout="vertical"
                        onFinish={changePassword}
                      >
                        <legend style={{ color: '#333', fontSize: '21px' }}>
                          {this.props.t('profile.password.title')}
                        </legend>

                        <Form.Item
                          name="password"
                          label={this.props.t('profile.password.new')}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: this.props.t('profile.password.password-required'),
                            },
                            () => ({
                              validator(_, value) {
                                if (value.length < 6) {
                                  return Promise.reject(new Error(error_validation_password_message));
                                }
                                const patron = new RegExp(
                                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-_=+#~])[A-Za-z\\d@$!%*?&\\-_=+#~]+$'
                                );
                                if (!patron.test(value)) {
                                  return Promise.reject(new Error(error_validation_password_message));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <Form.Item
                          name="confirm"
                          label={this.props.t('profile.password.confirm')}
                          dependencies={['password']}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: this.props.t('profile.password.password-required'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(confirm_password_message));
                              },
                            }),
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <ButtonPrimary
                          color="black"
                          shape="round"
                          size="large"
                          htmlType="submit"
                          style={{ width: '100%' }}
                        >
                          {this.props.t('profile.password.update')}
                        </ButtonPrimary>
                      </Form>
                    }
                  />
                </Col>
              </Row>
            </Spin>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(UserEdit);
