import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';

import Footer from 'components/Footer.jsx';
import LoginPage from 'views/Login/LoginPage';
import ChangePasswordPage from 'views/Login/ChangePasswordPage';
import videoLogin from 'assets/img/videoLogin.mov';

import pagesRoutes from 'routes/pages.jsx';
import VerifiedEmail from 'views/VerifiedEmail/VerifiedEmail';
import { ACCESS_TOKEN } from 'util/Constants';
import VerifyMfaPage from 'views/Login/VerifyMfaPage';

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoLoading: true,
    };
  }

  getPageClass() {
    var pageClass = '';
    switch (this.props.location.pathname) {
      case '/pages/login':
        pageClass = ' login-page';
        break;
      case '/pages/change-password':
        pageClass = ' change-password-page';
        break;
      case '/pages/verify-email':
        pageClass = ' verify-email';
        break;
      case '/pages/verify-mfa':
        pageClass = ' verify-mfa';
        break;
      default:
        pageClass = ' login-page';
        break;
    }
    return pageClass;
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }
  onChangePassword = (title, description) => {
    this.props.loadCurrentUser();
    this.props.history.push({
      pathname: '/pages/change-password',
      state: {
        title: title,
        description: description,
      },
    });
  };

  onMfaVerify = (userId, email, lastAccessAt) => {
    this.props.loadCurrentUser();
    let token = localStorage.getItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
    this.props.history.push({
      pathname: '/pages/verify-mfa',
      state: {
        userId,
        email,
        token: token,
        center: localStorage.getItem('center'),
        lastAccessAt,
      },
    });
  };

  render() {
    const spinStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      background: 'white',
      width: '100%',
      height: '100vh',
      zIndex: 5,
      padding: '100px',
    };

    return (
      <div>
        {this.state.videoLoading && <Spin style={spinStyle} />}
        <div className="wrapper wrapper-full-page">
          <div className={'full-page' + this.getPageClass()}>
            <div>
              <video
                onLoadedData={() => this.setState({ videoLoading: false })}
                autoPlay
                loop
                muted
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100vh',
                  objectFit: 'cover',
                }}
              >
                <source src={videoLogin} />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="content">
              {
                <Switch>
                  <Route
                    path="/login"
                    render={(props) => (
                      <LoginPage
                        onLogin={this.props.handleLoginForm}
                        onChangePassword={this.onChangePassword}
                        onMfaVerify={this.onMfaVerify}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/pages/change-password"
                    render={(props) => (
                      <ChangePasswordPage
                        updatedPassword={this.props.handleLoginForm}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/pages/verify-mfa"
                    render={(props) => (
                      <VerifyMfaPage
                        onLogin={this.props.handleLoginForm}
                        onChangePassword={this.onChangePassword}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/pages/verify-email/:userId/:code"
                    render={(props) => (
                      <VerifiedEmail
                        toLogin={this.props.handleLogout}
                        {...props}
                      />
                    )}
                  />
                  {pagesRoutes.map((prop, key) => {
                    return (
                      <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                  })}
                </Switch>
              }
            </div>
            <Footer transparent />
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;
