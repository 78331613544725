import React, { useEffect, useState } from 'react';

import {
  Select,
  Radio,
  notification,
  Spin,
  Breadcrumb,
  Card,
  Form,
  Input,
  Tooltip,
  ConfigProvider,
  DatePicker,
  Modal,
  Space,
} from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MdDirectionsCar } from 'react-icons/md';

import ButtonPrimary from 'components/Buttons/ButtonPrimary.jsx';

import esES from 'antd/locale/es_ES';
import caES from 'antd/locale/ca_ES';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'dayjs/locale/es';
import 'dayjs/locale/ca';
import dayjs from 'dayjs';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { getAllZonesIlla, getZoneAuthorizationsByUser } from 'util/APIUtils';
import { postWhitelistEntryCitizen } from 'repository/whitelistEntries/WhitelistEntryRepository';
import 'moment/locale/ca.js';
import colors from 'components/Buttons/colors';

const Option = Select.Option;
const { TextArea } = Input;

const LicensePlatesCreate = ({ currentUser, history, t }) => {
  const [zarZones, setZarZones] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [typeSelected, setTypeSelected] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reasonOption, setReasonOption] = useState(0);
  const [customReason, setCustomReason] = useState('');

  const reasons = [
    t('license-plates.citizen.reasons.charge'),
    t('license-plates.citizen.reasons.delivery'),
    t('license-plates.citizen.reasons.reduced-mobility'),
  ];

  useEffect(fetchZones, []);

  function fetchZones() {
    getZoneAuthorizationsByUser()
      .then((zoneAuthorizationsResponse) => {
        if (zoneAuthorizationsResponse.length === 0) {
          Modal.warning({
            title: t('license-plates.citizen.create.no-zones-modal.title'),
            content: t('license-plates.citizen.create.no-zones-modal.message'),
            onOk: () => history.push('/license-plates'),
            okText: t('license-plates.citizen.create.no-zones-modal.confirm-button'),
            centered: true,
          });
        }
        getAllZonesIlla(localStorage.getItem('center'))
          .then((zonesIllaResponse) => {
            let zoneAuthorizations = zoneAuthorizationsResponse.map((zoneAuthorization) => {
              let zoneName = '';
              zonesIllaResponse.forEach((zone) => {
                if (zone.id === zoneAuthorization.zoneId) zoneName = zone.name;
              });

              return {
                zoneName,
                ...zoneAuthorization,
              };
            });

            setZarZones(zoneAuthorizations);
            filterZones(zoneAuthorizations);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function filterZones(zones) {
    try {
      const exclusionReason = 'Necessito un accés causal (accés màxim de 3 mesos)';
      const filteredZones = zones.filter((zone) => zone.reason !== exclusionReason);
      setFilteredZones(filteredZones);
    } catch (error) {
      console.log(error);
    }
  }

  const zarZonesOptions = filteredZones.map((zone) => {
    console.log(zone);
    return <Option key={zone.zoneId}>{zone.zoneName}</Option>;
  });

  const onFinish = (values) => {
    setLoading(true);
    let finalReason;
    switch (reasonOption) {
      case 0:
        finalReason = '';
        break;
      case 4:
        finalReason = customReason;
        break;
      default:
        finalReason = reasons[reasonOption - 1];
        break;
    }
    postWhitelistEntryCitizen(
      values.plate,
      moment(values.date.$d).format('YYYY-MM-DDTHH:mm:ss').toString(),
      undefined,
      values.zone,
      finalReason
    ).then((response) => {
      if (response.status === 409) {
        notification['error']({
          message: 'LPR Connect',
          duration: 5,
          description: t('license-plates.citizen.create.already-exist'),
        });
      } else if (response.status === 201) {
        notification['success']({
          message: 'LPR Connect',
          description: t('license-plates.citizen.create.success'),
        });

        history.push('/license-plates');
      } else {
        notification['error']({
          message: 'LPR Connect',
          description: t('license-plates.citizen.create.error'),
        });
      }
      setLoading(false);
    });
  };

  return (
    <div className="main-content">
      <Breadcrumb
        separator=">"
        style={{ marginBottom: '10px', fontSize: 20 }}
      >
        <Breadcrumb.Item
          className="menu-history"
          onClick={() => history.push('/license-plates')}
        >
          <div style={{ display: 'inline-block', height: '30px' }}>
            <i
              style={{
                lineHeight: '30px',
                verticalAlign: 'middle',
                marginRight: '5px',
                fontSize: '30px',
              }}
            >
              <MdDirectionsCar />
            </i>
            {t('sidebar.license-plate')}
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('license-plates.citizen.create.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 24 }}
            spin
          />
        }
      >
        <Card title={t('license-plates.citizen.create.title')}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            size="large"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label={t('license-plates.admin.create.form.zone')}
              name="zone"
              rules={[
                {
                  required: true,
                  message: t('license-plates.admin.create.form.required-zone'),
                },
              ]}
            >
              <Select
                value={zarZones}
                size="large"
                placeholder={t('license-plates.admin.create.form.zone-placeholder')}
              >
                {zarZonesOptions}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('license-plates.admin.create.form.plate')}
              name="plate"
              rules={[
                {
                  required: true,
                  message: t('license-plates.admin.create.form.required-plate'),
                },
                {
                  pattern: new RegExp(/^[A-Z0-9]+$/),
                  message: t('license-plates.admin.create.form.plate-placeholder'),
                },
              ]}
            >
              <Input
                placeholder="Ex: 1234ABC"
                suffix={
                  <Tooltip
                    title={t('license-plates.admin.create.form.plate-placeholder')}
                    placement="right"
                    color={colors.blue.main}
                  >
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item
              label={t('license-plates.admin.create.form.time')}
              name="type"
              rules={[
                {
                  required: true,
                  message: t('license-plates.admin.create.form.required-time'),
                },
              ]}
            >
              <Radio.Group
                size="large"
                optionType="button"
                buttonStyle="solid"
                value={typeSelected}
                onChange={(event) => {
                  setTypeSelected(event.target.value);
                  setStartDate(null);
                }}
              >
                <Tooltip
                  placement="bottom"
                  title={t('license-plates.citizen.create.form.day-tooltip')}
                  color={colors.blue.main}
                >
                  <Radio value={'DAILY'}>{t('license-plates.citizen.create.form.day')}</Radio>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={t('license-plates.citizen.create.form.permanent-time-tooltip')}
                  color={colors.blue.main}
                >
                  <Radio
                    disabled={currentUser.isBasicUser()}
                    value={'PERMANENT'}
                  >
                    {t('license-plates.citizen.create.form.permanent-time')}
                  </Radio>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={t('license-plates.citizen.create.form.custom-time-tooltip')}
                  color={colors.blue.main}
                >
                  <Radio
                    disabled={currentUser.isBasicUser()}
                    value={'CUSTOM'}
                  >
                    {t('license-plates.citizen.create.form.custom-time')}
                  </Radio>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
            {typeSelected !== '' ? (
              <ConfigProvider locale={currentUser.language === 'ca' ? caES : esES}>
                <Form.Item
                  label={t('license-plates.admin.create.form.calendar-placeholder')}
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: 'És obligatori',
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={t('license-plates.admin.create.form.calendar-placeholder-1')}
                    style={{ width: '100%' }}
                    disabledDate={(current) => {
                      const marginDays = localStorage.getItem('center').toUpperCase() === 'TORELLO' ? 3 : 6;
                      return current.isBefore(moment().subtract(marginDays, 'day'));
                    }}
                    size="large"
                    value={startDate === null ? null : dayjs(startDate)}
                    onChange={(event) => {
                      setStartDate(event.$d);
                    }}
                    format={'DD/MM/YYYY'}
                  />
                </Form.Item>
              </ConfigProvider>
            ) : (
              <></>
            )}
            {localStorage.getItem('center').toUpperCase() === 'TORELLO' ? (
              <Form.Item
                label={t('license-plates.citizen.create.form.reason')}
                name="reason"
                rules={[
                  {
                    required: true,
                    message: t('license-plates.admin.create.form.plate'),
                  },
                ]}
              >
                <Radio.Group
                  size="large"
                  onChange={(event) => setReasonOption(event.target.value)}
                >
                  <Space direction="vertical">
                    <Radio value={1}>{reasons[0]}</Radio>
                    <Radio value={2}>{reasons[1]}</Radio>
                    <Radio value={3}>{reasons[2]}</Radio>
                    <Radio value={4}>{t('license-plates.citizen.create.form.other-reason')}</Radio>
                    {reasonOption === 4 ? (
                      <TextArea
                        onChange={(event) => setCustomReason(event.target.value)}
                        value={customReason}
                      />
                    ) : null}
                  </Space>
                </Radio.Group>
              </Form.Item>
            ) : null}

            <Form.Item
              label=" "
              colon={false}
            >
              <ButtonPrimary
                color="blue"
                shape="round"
                size="large"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                {t('license-plates.admin.add-button')}
              </ButtonPrimary>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default withTranslation('common')(LicensePlatesCreate);
