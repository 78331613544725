import React, { Component } from 'react';
import { ACCESS_TOKEN } from '../../util/Constants';
import * as AuthRepository from 'repository/auth/AuthRepository';
import * as UserRepository from 'repository/user/UserRepository';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Form, Input, Spin, Modal, notification } from 'antd';
import colors from 'components/Buttons/colors';
import { LoadingOutlined } from '@ant-design/icons';
import { MdPerson, MdPassword, MdEmail } from 'react-icons/md';
import { LuCalendarCheck2 } from 'react-icons/lu';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

const FormItem = Form.Item;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalEmail: '',
      loading: false,
    };
  }

  async resetPassword() {
    this.setState({ loading: true, modalVisible: false });
    try {
      var pathVariables = this.props.history.location.pathname.split('/');
      var center;
      if (pathVariables[pathVariables.length - 1] === 'login') center = 'nexotech';
      else center = pathVariables[pathVariables.length - 1];
      await UserRepository.resetPassword(this.state.modalEmail, center);

      notification['success']({
        message: 'ZinkinData Portal',
        description: this.props.t('login.password-sended'),
      });

      this.setState({ modalLicensePlate: '', modalEmail: '' });
    } catch (error) {
      notification['error']({
        message: 'ZinkinData Portal',
        description: error.message,
      });
    }
    this.setState({ loading: false });
  }

  setModalVisible(modalVisible) {
    this.setState({
      modalVisible,
    });
  }

  render() {
    const handleSubmit = (values) => {
      this.setState({ loading: true });
      var pathVariables = this.props.history.location.pathname.split('/');
      var center;
      if (pathVariables[pathVariables.length - 1] === 'login') center = 'nexotech';
      else center = pathVariables[pathVariables.length - 1];
      var request = {
        center: center,
        username: values.username,
        password: values.password,
      };
      AuthRepository.login(request)
        .then((response) => {
          switch (response.status) {
            case 401:
              response.text().then((text) => {
                let number = text.charAt(text.length - 2);
                let attempts = 4 - number;
                if (isNaN(attempts)) {
                  notification['error']({
                    message: this.props.t('login.incorrect-credentials'),
                    description: this.props.t('login.retry-credentials'),
                  });
                } else {
                  if (attempts > 0) {
                    notification['error']({
                      message: this.props.t('login.incorrect-credentials'),
                      description: `${this.props.t('login.before-block')}${attempts}`,
                    });
                  } else {
                    notification['error']({
                      message: this.props.t('login.user-blocked'),
                      description: this.props.t('login.admin-contact'),
                    });
                  }
                }
              });
              this.setState({ loading: false });
              break;
            case 403:
              notification['error']({
                message: this.props.t('login.user-blocked'),
                description: this.props.t('login.admin-contact'),
              });
              this.setState({ loading: false });
              break;
            case 500:
              notification['error']({
                message: 'ZinkinData Portal',
                description: 'Internal server error',
              });
              this.setState({ loading: false });
              break;
            default:
              localStorage.setItem(ACCESS_TOKEN, response.accessToken);
              UserRepository.getCurrentUser()
                .then((responseUser) => {
                  if (responseUser.firstLogin) {
                    this.props.onChangePassword(
                      'change-password-page.first-login.title',
                      'change-password-page.first-login.description'
                    );
                  } else if (responseUser.mfa) {
                    this.props.onMfaVerify(responseUser.id, responseUser.email, response.lastAccessAt);
                  } else if (responseUser.passwordExpired) {
                    this.props.onChangePassword(
                      'change-password-page.password-expire.title',
                      'change-password-page.password-expire.description'
                    );
                  } else {
                    this.props.onLogin();
                    if (response.lastAccessAt !== null) {
                      notification['info']({
                        message: this.props.t('login.last-access'),
                        description: moment(response.lastAccessAt).format('DD-MM-YYYY HH:mm:ss'),
                        icon: <LuCalendarCheck2 style={{ color: '#108ee9' }} />,
                        duration: 5,
                      });
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              break;
          }
        })
        .catch((error) => {
          notification['error']({
            message: 'Error',
            description: error.message,
          });
          this.setState({ loading: false });
        });
    };

    const iconStyles = { fontSize: 22, color: '#0085cb' };
    const loadingIcon = <LoadingOutlined />;

    return (
      <Spin
        spinning={this.state.loading}
        indicator={loadingIcon}
      >
        <Form
          onFinish={handleSubmit}
          className="login-form"
        >
          <FormItem name="username">
            <Input
              suffix={<MdPerson style={iconStyles} />}
              size="large"
              placeholder={this.props.t('login.username')}
              name="username"
            />
          </FormItem>
          <FormItem name="password">
            <Input
              size="large"
              type="password"
              placeholder={this.props.t('login.password')}
              name="password"
              suffix={<MdPassword style={iconStyles} />}
            />
          </FormItem>
          <FormItem>
            <ButtonPrimary
              color="blue"
              htmlType="submit"
              size="large"
              block
              shape="round"
            >
              {this.props.t('login.login')}
            </ButtonPrimary>
            <p style={{ textAlign: 'center', marginTop: '20px', marginBottom: '5px' }}>
              <span
                className="pass-recovery"
                onClick={() => {
                  this.setModalVisible(true);
                }}
                style={{ color: '#0064ad' }}
              >
                {this.props.t('login.forgot-password')}
              </span>
            </p>
          </FormItem>
        </Form>
        <Modal
          title={this.props.t('login.forgot-password')}
          wrapClassName="vertical-center-modal"
          open={this.state.modalVisible}
          onOk={() => this.resetPassword()}
          onCancel={() => this.setModalVisible(false)}
          okText={this.props.t('login.recover-button')}
          cancelText={this.props.t('login.cancel-button')}
          className="forgotPass"
          okButtonProps={{ style: { backgroundColor: colors.blue.main, borderColor: colors.blue.main } }}
        >
          <Form>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: this.props.t('login.required-email'),
                },
                {
                  type: 'email',
                  message: this.props.t('login.bad-email'),
                },
              ]}
            >
              <Input
                suffix={<MdEmail />}
                size="large"
                placeholder={this.props.t('login.email')}
                value={this.state.modalEmail}
                onChange={(event) => {
                  this.setState({ modalEmail: event.target.value });
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    );
  }
}

export default withTranslation('common')(LoginForm);
