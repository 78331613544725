import { createZoneAuthorization } from "util/APIUtils";
import { API_BASE_URL, buildOptionsWithAuth } from "util/Constants";

export async function postZoneAuthorization(id, username, reason, zoneId) {
    try {
        await createZoneAuthorization(id, zoneId, reason, username);
    } catch (error) {
        return console.log(error);
    }
}

export async function editZoneAuthorizationReason(zoneId, newReason) {
    try {
        return await createZoneAuthorization(zoneId, newReason);
    } catch (error) {
        return console.log(error);
    }
}

export async function updateZoneAuthorizationValidity(center, zoneAuthorizationId, validity){
    const headers = buildOptionsWithAuth("PUT");
    const url = `${API_BASE_URL}/admin/zone-authorizations/${center}/${zoneAuthorizationId}?validity=${validity}`;

    const response = await fetch(url, headers);
    if (response.status !== 200)
        throw new Error("Error intern de servidor");
}