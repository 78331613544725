import moment from "moment";
import { API_BASE_URL, buildOptionsWithAuth } from "util/Constants.js";

export async function postWhitelistEntry(username, centerName,type, licensePlate, startDate, finishDate, zoneIds, reason) {
    const headers = buildOptionsWithAuth("POST");
    const json = {
        centerName: centerName,
        plate: licensePlate,
        type: type,
        startDate: startDate !== null ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss').toString() : null,
        finishDate: finishDate !== null ? moment(finishDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss').toString() : null,
        zoneIds: zoneIds,
        reason: reason
    }
    const url = API_BASE_URL + "/admin/whitelist-entries/" + username;
    const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(json) }));
    if (response.status !== 201) throw new Error('Error intern de servidor');
    return response;
}

export async function postWhitelistEntryCitizen(licensePlate, startDate, endDate, zoneId, reason) {
    const headers = buildOptionsWithAuth("POST");
    var url = API_BASE_URL + "/whitelist-entries" +
        "?license-plate=" + licensePlate +
        "&type=DAILY" +
        "&start-date=" + startDate +
        "&zone-id=" + zoneId +
        "&center-name=" + localStorage.getItem("center")

    if (endDate !== undefined) {
        url += "&finish-date=" + endDate
    }

    if(reason !== ""){
        url += "&reason=" + reason
    }

    return fetch(url, headers)
        .then(response => {
            return response
        });
}

export function expireWhitelistEntry(whitelistEntryId) {
    const headers = buildOptionsWithAuth("PUT");
    const url = `${API_BASE_URL}/whitelist-entries/${whitelistEntryId}/expire?center-name=${localStorage.getItem("center")}`

    return fetch(url, headers)
        .then(response => {
            if (response.status !== 204)
                throw new Error("Error intern de servidor")
        });
}

export function updateWhitelistEntry({ id, licensePlate, startDate, finishDate, reason, status, validity, userId, centerName }) {
    const headers = buildOptionsWithAuth("PUT");
    const url = `${API_BASE_URL}/admin/${centerName}/whitelist-entries`;
    const requestBody = { id, licensePlate, startDate, finishDate, status, validity, reason, userId };

    return fetch(url, { ...headers, body: JSON.stringify(requestBody) })
        .then(response => {
            if (response.status !== 204)
                throw new Error("Error intern de servidor")
        });
}