import { Component } from 'react';

class LogoutView extends Component {
  componentWillMount() {
    this.props.handleLogout();
  }

  render() {
    return null;
  }
}

export default LogoutView;
