import React from 'react';
import { Alert, Card, Space } from 'antd';
import { withTranslation } from 'react-i18next';
import { MdPassword } from 'react-icons/md';
import VerifyMfaForm from './VerifyMfaForm';

const VerifyMfaPage = (props) => {
  return (
    <Space
      align="center"
      direction="vertical"
      style={{ width: '100%', marginTop: '5%' }}
    >
      <Card
        title={props.t('mfa.title')}
        size="large"
        style={{ width: '500px' }}
      >
        <Space
          direction="vertical"
          align="center"
          style={{ width: '100%' }}
          size="large"
        >
          <MdPassword style={{ fontSize: '40px', marginBottom: '-30%' }} />
          <p style={{ fontSize: '30px' }}>{props.t('mfa.title-1')}</p>
        </Space>
        <Alert
          message={
            <div style={{ textAlign: 'justify' }}>
              {props.t('mfa.description')}
              <strong>{props.location.state.email}</strong>.
            </div>
          }
          type="info"
          style={{ marginBottom: '3%' }}
        />
        <VerifyMfaForm {...props} />
      </Card>
    </Space>
  );
};

export default withTranslation('common')(VerifyMfaPage);
