import React, { useState } from 'react';
import { Button } from 'antd';
import colors from './colors';

const ButtonPrimary = ({ style, color = 'blue', fontColor, disabled, onClick, ...restProps }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    if (!disabled) {
      restProps.onMouseEnter && restProps.onMouseEnter();
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    restProps.onMouseLeave && restProps.onMouseLeave();
    setHovered(false);
  };

  const bgColorProperty = !disabled && {
    backgroundColor: hovered ? colors[color].lighter : colors[color].main,
    color: colors[fontColor]?.main,
  };

  const buttonStyles = {
    ...style,
    ...bgColorProperty,
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.07)',
    transition: 'background-color 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgb(0,0,0,0.2)',
  };

  return (
    <Button
      {...restProps}
      type="primary"
      className="custom-button-primary"
      style={buttonStyles}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export default ButtonPrimary;
