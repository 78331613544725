import React, { Component } from 'react';
import Card from 'components/Card.jsx';
import { Grid, Row, Col } from 'react-bootstrap';
import {
  Spin,
  Space,
  Table,
  Form,
  Input,
  notification,
  Select,
  Tag,
  Breadcrumb,
  ConfigProvider,
  DatePicker,
} from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import esES from 'antd/locale/es_ES';
import caES from 'antd/locale/ca_ES';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'dayjs/locale/es';
import 'dayjs/locale/ca';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { getAllActionTypes, getAllResourceTypes, getAuditLogs } from 'repository/auditLogs/AuditLogsRepository';
import { MdManageSearch } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;

class AuditLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      auditLogList: [],
      currentCenter: localStorage.getItem('center'),

      endDate: undefined,
      startDate: undefined,
      actionTypes: [],
      resourceTypes: [],
      actionTypesSearch: undefined,
      resourceTypesSearch: undefined,
      usernameSearch: undefined,
      resourceDataSearch: undefined,
      resetButton: true,
      previousButton: true,
      nextButton: false,
      pageNumber: 0,
    };

    this.columns = [
      {
        title: this.props.t('audit-logs.table.date-title'),
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => moment(a.date) - moment(b.date),
        render: (text, record) => {
          if (record.date !== undefined && record.date !== null)
            return moment(record.date).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('audit-logs.table.action-type-title'),
        dataIndex: 'actionType',
        key: 'actionType',
        render: (text, record) => {
          switch (record.actionType) {
            case 'CREATE':
              return <Tag color="blue">{this.props.t('audit-logs.action-types.create')}</Tag>;
            case 'UPDATE':
              return <Tag color="orange">{this.props.t('audit-logs.action-types.update')}</Tag>;
            case 'EXPIRE':
              return <Tag color="volcano">{this.props.t('audit-logs.action-types.expire')}</Tag>;
            case 'DELETE':
              return <Tag color="red">{this.props.t('audit-logs.action-types.delete')}</Tag>;
            case 'LOGIN_ATTEMPT':
              return <Tag color="cyan">{this.props.t('audit-logs.action-types.login-attempt')}</Tag>;
            default:
              return <div></div>;
          }
        },
      },
      {
        title: this.props.t('audit-logs.table.resource-type-title'),
        dataIndex: 'resourceType',
        key: 'resourceType',
        render: (text, record) => {
          switch (record.resourceType) {
            case 'WHITELIST_ENTRY':
              return this.props.t('audit-logs.resource-types.whitelist-entry');
            case 'ZONE_AUTHORIZATION':
              return this.props.t('audit-logs.resource-types.zone-authorization');
            case 'SPECIAL_LICENSE_PLATE':
              return this.props.t('audit-logs.resource-types.special-license-plate');
            case 'CENTER_ENTITY':
              return this.props.t('audit-logs.resource-types.center-entity');
            case 'USER':
              return this.props.t('audit-logs.resource-types.user');
            case 'SECURITY':
              return this.props.t('audit-logs.resource-types.security');
            default:
              return '';
          }
        },
      },
      {
        title: this.props.t('audit-logs.table.resource-data-title'),
        dataIndex: 'resourceData',
        key: 'resourceData',
      },
      {
        title: 'Creat per',
        dataIndex: 'username',
        key: 'username',
      },
    ];
  }

  componentDidMount() {
    getAuditLogs(this.state.currentCenter, null, null, null, null, null, null, this.state.pageNumber)
      .then((response) => this.setState({ auditLogList: response }))
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      });

    getAuditLogs(this.state.currentCenter, null, null, null, null, null, null, this.state.pageNumber + 1)
      .then((response) => {
        if (response.length === 0) this.setState({ nextButton: true });
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      });

    getAllActionTypes()
      .then((response) => {
        this.setState({ actionTypes: response });
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      });

    getAllResourceTypes()
      .then((response) => {
        this.setState({
          resourceTypes: response,
          loading: false,
        });
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getAllAuditLogs() {
    getAuditLogs(this.state.currentCenter, null, null, null, null, null, null, 0)
      .then((response) => this.setState({ auditLogList: response }))
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      });

    getAuditLogs(this.state.currentCenter, null, null, null, null, null, null, 1)
      .then((response) => {
        if (response.length === 0) this.setState({ nextButton: true });
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      });
  }

  getFilteredAuditLogs(page) {
    getAuditLogs(
      this.state.currentCenter,
      this.state.resourceTypesSearch,
      this.state.actionTypesSearch,
      this.state.startDate,
      this.state.endDate,
      this.state.usernameSearch,
      this.state.resourceDataSearch,
      page
    )
      .then((response) => {
        this.setState({
          auditLogList: response,
          resetButton: false,
        });
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      });

    getAuditLogs(
      this.state.currentCenter,
      this.state.resourceTypesSearch,
      this.state.actionTypesSearch,
      this.state.startDate,
      this.state.endDate,
      this.state.usernameSearch,
      this.state.resourceDataSearch,
      page + 1
    )
      .then((response) => {
        if (response.length === 0) {
          this.setState({
            nextButton: true,
          });
        } else {
          this.setState({
            nextButton: false,
          });
        }
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: error.message,
        });
      });
  }

  getTableData() {
    this.getAllAuditLogs();
  }

  resetForm = () => {
    this.setState({
      startDate: undefined,
      endDate: undefined,
      resetButton: true,
      actionTypesSearch: undefined,
      resourceTypesSearch: undefined,
      usernameSearch: undefined,
      resourceDataSearch: undefined,
      pageNumber: 0,
    });

    this.getTableData();
  };

  updatePageNumber(newPageNumber) {
    return new Promise((resolve) => {
      this.setState({
        pageNumber: this.state.pageNumber + newPageNumber,
      });

      if (newPageNumber > 0) {
        this.setState({
          previousButton: false,
        });
      } else {
        if (this.state.pageNumber + newPageNumber === 0) {
          this.setState({
            previousButton: true,
          });
        } else {
          this.setState({
            previousButton: false,
          });
        }
      }
      resolve();
    });
  }

  async nextPage() {
    await this.updatePageNumber(1);
    this.getFilteredAuditLogs(this.state.pageNumber);
  }

  async previousPage() {
    await this.updatePageNumber(-1);
    this.getFilteredAuditLogs(this.state.pageNumber);
  }

  getActionTypesArray() {
    let actionTypesArray = [];
    this.state.actionTypes.forEach((element) => {
      switch (element) {
        case 'CREATE':
          actionTypesArray.push({
            actionType: 'CREATE',
            traduccion: this.props.t('audit-logs.action-types.create'),
          });
          break;
        case 'UPDATE':
          actionTypesArray.push({
            actionType: 'UPDATE',
            traduccion: this.props.t('audit-logs.action-types.update'),
          });
          break;
        case 'EXPIRE':
          actionTypesArray.push({
            actionType: 'EXPIRE',
            traduccion: this.props.t('audit-logs.action-types.expire'),
          });
          break;
        case 'DELETE':
          actionTypesArray.push({
            actionType: 'DELETE',
            traduccion: this.props.t('audit-logs.action-types.delete'),
          });
          break;
        case 'LOGIN_ATTEMPT':
          actionTypesArray.push({
            actionType: 'LOGIN_ATTEMPT',
            traduccion: this.props.t('audit-logs.action-types.login-attempt'),
          });
          break;
        default:
          actionTypesArray.push({
            actionType: '',
            traduccion: '',
          });
      }
    });

    return actionTypesArray;
  }

  getResourceTypesArray() {
    let resourceTypesArray = [];
    this.state.resourceTypes.forEach((element) => {
      switch (element) {
        case 'WHITELIST_ENTRY':
          resourceTypesArray.push({
            resourceType: 'WHITELIST_ENTRY',
            traduccion: this.props.t('audit-logs.resource-types.whitelist-entry'),
          });
          break;
        case 'ZONE_AUTHORIZATION':
          resourceTypesArray.push({
            resourceType: 'ZONE_AUTHORIZATION',
            traduccion: this.props.t('audit-logs.resource-types.zone-authorization'),
          });
          break;
        case 'SPECIAL_LICENSE_PLATE':
          resourceTypesArray.push({
            resourceType: 'SPECIAL_LICENSE_PLATE',
            traduccion: this.props.t('audit-logs.resource-types.special-license-plate'),
          });
          break;
        case 'CENTER_ENTITY':
          resourceTypesArray.push({
            resourceType: 'CENTER_ENTITY',
            traduccion: this.props.t('audit-logs.resource-types.center-entity'),
          });
          break;
        case 'USER':
          resourceTypesArray.push({
            resourceType: 'USER',
            traduccion: this.props.t('audit-logs.resource-types.user'),
          });
          break;
        case 'SECURITY':
          resourceTypesArray.push({
            resourceType: 'SECURITY',
            traduccion: this.props.t('audit-logs.resource-types.security'),
          });
          break;
        default:
          resourceTypesArray.push({
            resourceType: '',
            traduccion: '',
          });
          break;
      }
    });
    return resourceTypesArray;
  }

  render() {
    if (this.props.currentUser.role !== 'ROLE_ADMIN' && this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );
      const actionTypesOptions = this.getActionTypesArray().map((record) => (
        <Option value={record.actionType}>{record.traduccion}</Option>
      ));
      const resourceTypesOptions = this.getResourceTypesArray().map((record) => (
        <Option value={record.resourceType}>{record.traduccion}</Option>
      ));

      return (
        <div className="main-content">
          {this.state.alert}
          <Grid fluid>
            <Breadcrumb
              separator=">"
              style={{ marginBottom: '10px', fontSize: 20 }}
            >
              <Breadcrumb.Item>
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i
                    style={{
                      lineHeight: '30px',
                      verticalAlign: 'middle',
                      marginRight: '5px',
                      fontSize: '30px',
                    }}
                  >
                    <MdManageSearch />
                  </i>
                  {this.props.t('audit-logs.breadcrumb-title')}
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Card
                    content={
                      <div>
                        <Form
                          className="filter-form"
                          layout="inline"
                        >
                          <FormItem>
                            <Select
                              value={this.state.actionTypesSearch}
                              showSearch
                              size="large"
                              style={{ height: '40px', width: '150px' }}
                              optionFilterProp="children"
                              placeholder={this.props.t('audit-logs.action-selector-placeholder')}
                              onChange={(value) => {
                                if (typeof value !== 'undefined') {
                                  this.setState({
                                    actionTypesSearch: value,
                                    resetButton: false,
                                  });
                                } else {
                                  this.setState({
                                    actionTypesSearch: undefined,
                                  });
                                }
                              }}
                              onSelect={(value) => {
                                this.setState({
                                  actionTypesSearch: value,
                                  resetButton: false,
                                });
                              }}
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear
                              defaultActiveFirstOption={false}
                            >
                              {actionTypesOptions}
                            </Select>
                          </FormItem>
                          <FormItem>
                            <Select
                              showSearch
                              size="large"
                              style={{ height: '40px', width: '200px' }}
                              optionFilterProp="children"
                              placeholder={this.props.t('audit-logs.resource-selector-placeholder')}
                              onChange={(value) => {
                                if (typeof value !== 'undefined') {
                                  this.setState({
                                    resourceTypesSearch: value,
                                    resetButton: false,
                                  });
                                } else {
                                  this.setState({
                                    resourceTypesSearch: undefined,
                                  });
                                }
                              }}
                              onSelect={(value) => {
                                this.setState({
                                  resourceTypesSearch: value,
                                  resetButton: false,
                                });
                              }}
                              name="camara"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear
                              value={this.state.resourceTypesSearch}
                              defaultActiveFirstOption={false}
                            >
                              {resourceTypesOptions}
                            </Select>
                          </FormItem>
                          <FormItem>
                            {
                              <ConfigProvider locale={this.props.currentUser === 'ca' ? caES : esES}>
                                <RangePicker
                                  style={{ width: '100%' }}
                                  size="large"
                                  onChange={(event) => {
                                    this.setState({
                                      startDate: event[0].$d,
                                      endDate: event[1].$d,
                                      resetButton: false,
                                    });
                                  }}
                                  format={'DD/MM/YYYY HH:mm'}
                                  showTime
                                />
                              </ConfigProvider>
                            }
                          </FormItem>
                          <FormItem>
                            {
                              <Input
                                size="large"
                                placeholder={this.props.t('audit-logs.resource-data-input')}
                                name="resourceDataSearch"
                                value={this.state.resourceDataSearch}
                                onChange={(event) => {
                                  this.setState({
                                    resourceDataSearch: event.target.value,
                                  });
                                  this.setState({ resetButton: false });
                                }}
                              />
                            }
                          </FormItem>
                          <FormItem>
                            {
                              <Input
                                suffix={<UserOutlined />}
                                size="large"
                                placeholder={this.props.t('audit-logs.user-input-placeholder')}
                                name="usernameSearch"
                                value={this.state.usernameSearch}
                                onChange={(event) => {
                                  this.setState({
                                    usernameSearch: event.target.value,
                                  });
                                  this.setState({ resetButton: false });
                                }}
                              />
                            }
                          </FormItem>
                          <FormItem>
                            <ButtonPrimary
                              onClick={() => {
                                this.getFilteredAuditLogs(0);
                                this.setState({ previousButton: true });
                              }}
                              color="blue"
                              size="large"
                              shape="round"
                              disabled={this.state.searchButton}
                              style={{ width: '100%' }}
                            >
                              {this.props.t('audit-logs.search-button')}
                            </ButtonPrimary>
                          </FormItem>
                          <FormItem>
                            <ButtonPrimary
                              style={{ width: '100%' }}
                              onClick={() => {
                                this.resetForm();
                              }}
                              disabled={this.state.resetButton}
                              color="black"
                              size="large"
                              shape="round"
                            >
                              {this.props.t('audit-logs.reset-fields-button')}
                            </ButtonPrimary>{' '}
                          </FormItem>
                        </Form>
                        <Row style={{ padding: '15px' }}>
                          <Col md={12}>
                            <Table
                              pagination={false}
                              dataSource={this.state.auditLogList}
                              columns={this.columns}
                            />
                          </Col>
                        </Row>
                        <Row style={{ padding: '15px' }}>
                          <Space
                            direction="horizontal"
                            style={{ float: 'right' }}
                          >
                            <ButtonPrimary
                              style={{ margin: '0 8px' }}
                              color="blue"
                              shape="round"
                              onClick={() => this.previousPage()}
                              disabled={this.state.previousButton}
                            >
                              {this.props.t('audit-logs.back-button')}
                            </ButtonPrimary>
                            <ButtonPrimary
                              color="blue"
                              shape="round"
                              onClick={() => this.nextPage()}
                              disabled={this.state.nextButton}
                            >
                              {this.props.t('audit-logs.next-button')}
                            </ButtonPrimary>
                          </Space>
                        </Row>
                      </div>
                    }
                  />
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(AuditLogs);
