import React, { Component } from 'react';
import Card from 'components/Card.jsx';
import LicensePlateInput from '../../components/LicensePlateInput.jsx';
import { Grid, Row, Col } from 'react-bootstrap';
import { Select, notification, Spin, Breadcrumb, Upload, Table, Popconfirm, Form, Input, Modal, Radio } from 'antd';
import { LoadingOutlined, UploadOutlined, BarcodeOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { withTranslation } from 'react-i18next';

import { getCenterEntitiesByCenter } from 'util/APIUtils';
import {
  postSpecialLicensePlate,
  postSpecialLicensePlateList,
} from 'repository/specialLicensePlate/SpecialLicensePlateRepository';
import { postCenterEntity } from 'repository/centerEntity/CenterEntityRepository';
import { MdAdd, MdFireTruck } from 'react-icons/md';
import Button from 'antd-button-color';
import ButtonPrimary from 'components/Buttons/ButtonPrimary.jsx';

const Option = Select.Option;
const FormItem = Form.Item;

class SpecialLicensePlatesCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      centerEntities: [],
      centername: localStorage.getItem('center'),
      role: this.props.currentUser.role,
      uploads: [],
      licensePlate: undefined,
      centerEntitySelected: undefined,

      licensePlateToEdit: '',
      centerEntityToCreate: '',
      indexToEdit: undefined,

      modalvisible: false,
      confirmLoading: false,
      createCenterEntityOption: 1,
      centerEntityToEditOption: '',

      allOK: false,
    };

    this.columns = [
      {
        title: this.props.t('special-license-plates.create.multi.table.status'),
        dataIndex: 'status',
        width: 100,
        align: 'center',
        render: (_, record) =>
          this.state.uploads.length >= 1 ? (
            <div className="actions-right">
              {' '}
              {!this.validateCenterEntity(record.centerEntityName) || !/^[A-Z0-9]*$/.test(record.licensePlate) ? (
                <i
                  className="fa fa-exclamation-triangle"
                  style={{ fontSize: '20px', color: 'red' }}
                />
              ) : (
                <i
                  className="fa fa-check-circle"
                  style={{ fontSize: '20px', color: 'green' }}
                />
              )}
            </div>
          ) : null,
      },
      {
        title: this.props.t('special-license-plates.create.multi.table.plate'),
        dataIndex: 'licensePlate',
        key: 'licensePlate',
        render(text, record) {
          return {
            props: {
              style: {
                color: /^[A-Z0-9]*$/.test(text) ? 'green' : 'red',
                fontWeight: 'bold',
              },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: this.props.t('special-license-plates.create.multi.table.center-entity'),
        dataIndex: 'centerEntityName',
        key: 'centerEntityName',
        render: (text, record) => {
          return {
            props: {
              style: {
                color: this.validateCenterEntity(text) ? 'green' : 'red',
                fontWeight: 'bold',
              },
            },
            children: <span>{text}</span>,
          };
        },
      },
      {
        title: this.props.t('special-license-plates.create.multi.table.actions'),
        dataIndex: 'delete',
        render: (_, record) =>
          this.state.uploads.length >= 1 ? (
            <div className="actions-right">
              <Popconfirm
                title={this.props.t('special-license-plates.create.multi.delete-modal.title')}
                cancelText={this.props.t('special-license-plates.create.multi.delete-modal.confirm-button')}
                okText={this.props.t('special-license-plates.create.multi.delete-modal.cancel-button')}
                onConfirm={() => this.handleDelete(record)}
              >
                <Button
                  type="primary"
                  danger
                  with="link"
                  width="100%"
                >
                  <i className="fa fa-times" />
                </Button>{' '}
              </Popconfirm>
              {!this.validateCenterEntity(record.centerEntityName) || !/^[A-Z0-9]*$/.test(record.licensePlate) ? (
                <Button
                  type="warning"
                  shape="round"
                  size="large"
                  onClick={() => {
                    this.setModalVisible(record.licensePlate, record.centerEntityName);
                  }}
                >
                  {this.props.t('special-license-plates.create.multi.table.correct-button')}
                </Button>
              ) : (
                <></>
              )}
            </div>
          ) : null,
      },
    ];

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setData = this.setData.bind(this);
    this.validateCenterEntity = this.validateCenterEntity.bind(this);
  }

  validateCenterEntity(centerEntity) {
    return this.state.centerEntities.find((center) => center.name === centerEntity) !== undefined;
  }

  handleDelete(item) {
    this.setState(
      {
        uploads: this.state.uploads.filter((upload) => upload.licensePlate !== item.licensePlate),
      },
      () => this.validateAllUploads()
    );
  }

  setModalVisible(matricula, center) {
    if (this.validateCenterEntity(center)) {
      this.setState(
        {
          createCenterEntityOption: 2,
          licensePlateToEdit: matricula,
          centerEntityToEditOption: center,
        },
        () => {
          this.setState({
            modalvisible: true,
            indexToEdit: this.state.uploads.findIndex(
              (upload) => upload.licensePlate === matricula && upload.centerEntityName === center
            ),
          });
        }
      );
    } else {
      this.setState(
        {
          createCenterEntityOption: 1,
          licensePlateToEdit: matricula,
          centerEntityToCreate: center,
        },
        () => {
          this.setState({
            modalvisible: true,
            indexToEdit: this.state.uploads.findIndex(
              (upload) => upload.licensePlate === matricula && upload.centerEntityName === center
            ),
          });
        }
      );
    }
  }

  componentDidMount() {
    getCenterEntitiesByCenter(this.state.centername)
      .then((response) => {
        this.setState({
          centerEntities: response,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSubmit() {
    this.setState({
      loading: true,
    });

    if (this.state.centerEntitySelected === undefined) {
      this.setState({ loading: false });
      notification['error']({
        message: 'ZinkinData Portal',
        description: this.props.t('special-license-plates.create.single.center-entity-required'),
      });
    } else {
      postSpecialLicensePlate(this.state.licensePlate, this.state.centername, this.state.centerEntitySelected)
        .then(() => {
          notification['success']({
            message: 'ZinkinData Portal',
            description: this.props.t('special-license-plates.create.single.success'),
          });

          this.setState({ loading: false });
          this.props.history.push('/special-license-plates');
        })
        .catch((error) => {
          notification['error']({
            message: 'ZinkinData Portal',
            description: this.props.t('special-license-plates.create.single.error'),
          });

          this.setState({ loading: false });
        });
    }
  }

  onLicensePlateChange = (value) => {
    this.setState({ licensePlate: value });
  };

  uploadFile(file) {
    this.setState({ uploads: undefined });
    const reader = new FileReader();
    reader.onload = (e) => {
      Papa.parse(e.target.result, {
        header: false,
        skipEmptyLines: true,
        complete: this.setData,
      });
    };
    reader.readAsText(file);

    return false;
  }

  validateAllUploads() {
    let valid = true;
    this.state.uploads.forEach((upload) => {
      if (!this.validateCenterEntity(upload.centerEntityName) || !/^[A-Z0-9]*$/.test(upload.licensePlate)) {
        valid = false;
      }
    });
    if (valid) {
      this.setState({
        allOK: true,
      });
    }
  }

  setData(data) {
    this.setState({ uploads: this.createJson(data.data) }, () => {
      this.validateAllUploads();
    });
  }

  createJson(arrays) {
    var headers = ['licensePlate', 'centerEntityName'];
    let json = [];
    arrays.forEach((array) => {
      var obj = {};
      for (var i = 0; i < array.length; i++) {
        obj[headers[i]] = array[i].toUpperCase();
      }
      json.push(obj);
    });
    return json;
  }

  editSpecialLicensePlate() {
    var list = this.state.uploads;
    this.setState({ confirmLoading: true, uploads: [] });

    if (this.state.createCenterEntityOption === 1 && this.state.centerEntityToCreate !== undefined) {
      postCenterEntity(this.state.centerEntityToCreate.toUpperCase(), this.state.centername)
        .then((response) => {
          getCenterEntitiesByCenter(this.state.centername).then((response) => {
            this.setState({ centerEntities: response });
          });

          return new Promise((resolve) => {
            setTimeout(() => {
              list[this.state.indexToEdit].centerEntityName = this.state.centerEntityToCreate.toUpperCase();
              list[this.state.indexToEdit].licensePlate = this.state.licensePlateToEdit.toUpperCase();
              this.setState({ uploads: list }, () => {
                this.validateAllUploads();
                this.setState({ modalvisible: false, confirmLoading: false });
              });
              resolve();
            }, 1000);
          });
        })
        .catch((error) => {
          notification['error']({
            message: 'ZinkinData Portal',
            description: error.message,
          });

          this.setState({ modalvisible: false, confirmLoading: false });
        });
    } else if (this.state.createCenterEntityOption === 2) {
      return new Promise((resolve) => {
        setTimeout(() => {
          list[this.state.indexToEdit].centerEntityName = this.state.centerEntityToEditOption.toUpperCase();
          list[this.state.indexToEdit].licensePlate = this.state.licensePlateToEdit.toUpperCase();
          this.setState({ uploads: list }, () => {
            this.validateAllUploads();
            this.setState({ modalvisible: false, confirmLoading: false });
          });
          resolve();
        }, 1000);
      });
    }
  }

  createUploadSpecialLicensePlates() {
    this.setState({
      loading: true,
    });
    postSpecialLicensePlateList(this.state.centername, this.state.uploads)
      .then((response) => {
        notification['success']({
          message: 'ZinkinData Portal',
          description: this.props.t('special-license-plates.create.single.success'),
        });

        this.setState({ loading: false });
        this.props.history.push('/special-license-plates');
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: this.props.t('special-license-plates.create.single.error'),
        });

        this.setState({ loading: false });
      });
  }

  render() {
    if (this.props.currentUser.role !== 'ROLE_ADMIN' && this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const typesOptions = this.state.centerEntities.map((type) => (
        <Option
          key={type.id}
          typeName={type.name}
        >
          {type.name}
        </Option>
      ));
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      const onFinish = (values) => {
        this.editSpecialLicensePlate();
      };

      const plate_pattern_error = () => this.props.t('special-license-plates.create.multi.correct-modal.pattern-plate');

      return (
        <div className="main-content">
          {this.state.alert}
          <Grid fluid>
            <Breadcrumb
              separator=">"
              style={{ marginBottom: '10px', fontSize: 20 }}
            >
              <Breadcrumb.Item
                className="menu-history"
                onClick={() => this.props.history.push('/special-license-plates')}
              >
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i
                    style={{
                      lineHeight: '30px',
                      verticalAlign: 'middle',
                      marginRight: '5px',
                      fontSize: '30px',
                    }}
                  >
                    <MdFireTruck />
                  </i>
                  {this.props.t('sidebar.special-license-plates')}
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{this.props.t('special-license-plates.create.title')}</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Card
                    content={
                      <div style={{ padding: '10px 15px' }}>
                        <Row>
                          <Col
                            style={{ marginBottom: '20px' }}
                            md={4}
                          >
                            <span className="title-select">
                              {this.props.t('special-license-plates.create.single.form.plate')}
                            </span>
                            <LicensePlateInput
                              value={this.state.licensePlate}
                              onChange={this.onLicensePlateChange}
                            />
                            <span style={{ color: '#868181' }}>
                              {this.props.t('special-license-plates.create.single.form.plate-placeholder')}
                            </span>
                          </Col>

                          <Col
                            style={{ marginBottom: '20px' }}
                            md={4}
                          >
                            <span className="title-select">
                              {this.props.t('special-license-plates.create.single.form.center-entity')}
                            </span>
                            <Select
                              value={this.state.centerEntitySelected}
                              size="large"
                              placeholder={this.props.t(
                                'special-license-plates.create.single.form.center-entity-placeholder'
                              )}
                              onChange={(value, record) => {
                                this.setState({ centerEntitySelected: value });
                              }}
                            >
                              {typesOptions}
                            </Select>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <ButtonPrimary
                              type="success"
                              shape="round"
                              size="large"
                              style={{
                                margin: '10px 0 20px 0',
                              }}
                              onClick={() => this.handleSubmit()}
                            >
                              {this.props.t('special-license-plates.create.single.add-button')}
                              <MdAdd
                                style={{
                                  verticalAlign: 'sub',
                                  fontSize: '20',
                                  marginLeft: '5px',
                                }}
                              />
                            </ButtonPrimary>
                          </Col>
                        </Row>
                      </div>
                    }
                  />
                </Spin>

                {this.state.role === 'ROLE_ADMIN' || this.state.role === 'ROLE_SUPERADMIN' ? (
                  <Spin
                    spinning={this.state.loading}
                    indicator={antIcon}
                  >
                    <Card
                      content={
                        <div style={{ padding: '10px 15px' }}>
                          <h5 className="title-select">{this.props.t('special-license-plates.create.multi.title')}</h5>
                          <Upload
                            name="file"
                            accept=".csv"
                            beforeUpload={(file) => {
                              this.uploadFile(file);
                              return false;
                            }}
                            onRemove={() => {
                              this.setState({
                                uploads: undefined,
                                allOK: false,
                              });
                            }}
                            maxCount={1}
                            listType="picture"
                          >
                            <ButtonPrimary
                              color="blue"
                              shape="round"
                            >
                              <UploadOutlined /> {this.props.t('special-license-plates.create.multi.import-button')}
                            </ButtonPrimary>
                          </Upload>

                          <Table
                            dataSource={this.state.uploads}
                            columns={this.columns}
                            pagination={false}
                            scroll={{ y: 500 }}
                            style={{ marginTop: '20px' }}
                            size="large"
                          />
                          <ButtonPrimary
                            color="blue"
                            shape="round"
                            size="large"
                            disabled={this.state.allOK ? false : true}
                            style={{ marginTop: 16 }}
                            onClick={() => this.createUploadSpecialLicensePlates()}
                          >
                            {this.props.t('special-license-plates.create.multi.add-button')}
                          </ButtonPrimary>
                        </div>
                      }
                    />
                  </Spin>
                ) : null}
              </Col>
            </Row>
            {this.state.role === 'ROLE_ADMIN' || this.state.role === 'ROLE_SUPERADMIN' ? (
              <Modal
                title={this.props.t('special-license-plates.create.multi.correct-modal.title')}
                open={this.state.modalvisible}
                onOk={() => this.editSpecialLicensePlate()}
                onCancel={() => this.setState({ modalvisible: false })}
                width={600}
                confirmLoading={this.state.confirmLoading}
                footer={null}
              >
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  size="large"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 12 }}
                  onFinish={onFinish}
                >
                  <FormItem
                    label={this.props.t('special-license-plates.create.multi.correct-modal.plate')}
                    name="matriculaEdit"
                    initialValue={this.state.licensePlateToEdit}
                    defaultValue={this.state.licensePlateToEdit}
                    rules={[
                      {
                        required: true,
                        message: this.props.t('special-license-plates.create.multi.correct-modal.required-plate'),
                      },
                      () => ({
                        validator(_, value) {
                          if (/^[A-Z0-9]*$/.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(plate_pattern_error));
                        },
                      }),
                    ]}
                  >
                    <Input
                      suffix={<BarcodeOutlined />}
                      size="large"
                      initialValue={this.state.licensePlateToEdit}
                      defaultValue={this.state.licensePlateToEdit}
                      value={this.state.licensePlateToEdit}
                      required
                      onChange={(event) => {
                        this.setState({
                          licensePlateToEdit: event.target.value,
                        });
                      }}
                    />
                  </FormItem>
                  <FormItem
                    label={this.props.t('special-license-plates.create.multi.correct-modal.option')}
                    rules={[
                      {
                        required: true,
                        message: this.props.t('special-license-plates.create.multi.correct-modal.required-option'),
                      },
                    ]}
                    style={{ marginBottom: '10px', marginTop: '20px' }}
                  >
                    <Radio.Group
                      value={this.state.createCenterEntityOption}
                      onChange={(event) => {
                        this.setState({
                          createCenterEntityOption: event.target.value,
                        });
                      }}
                    >
                      <Radio value={1}>
                        {this.props.t('special-license-plates.create.multi.correct-modal.option-1')}
                      </Radio>
                      <Radio value={2}>
                        {this.props.t('special-license-plates.create.multi.correct-modal.option-2')}
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                  {this.state.createCenterEntityOption === 1 ? (
                    <FormItem
                      label={this.props.t('special-license-plates.create.multi.correct-modal.center-entity-name')}
                      name="centerEntityOption"
                      initialValue={this.state.centerEntityToCreate}
                      defaultValue={this.state.centerEntityToCreate}
                      value={this.state.centerEntityOption}
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            'special-license-plates.create.multi.correct-modal.required-center-entity-name'
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        name="matriculaEdit"
                        value={this.state.centerEntityToCreate}
                        defaultValue={this.state.centerEntityToCreate}
                        initialValue={this.state.centerEntityToCreate}
                        onChange={(event) => {
                          this.setState({
                            centerEntityToCreate: event.target.value,
                          });
                        }}
                      />
                    </FormItem>
                  ) : (
                    <FormItem
                      label={this.props.t('special-license-plates.create.multi.correct-modal.center-entity"')}
                      name="centerEntity"
                    >
                      <Select
                        defaultValue={this.state.centerEntityToEditOption}
                        size="large"
                        placeholder={this.props.t(
                          'special-license-plates.create.multi.correct-modal.center-entity-placeholder'
                        )}
                        onChange={(value, record) => {
                          this.setState({
                            centerEntityToEditOption: record.typeName,
                          });
                        }}
                      >
                        {typesOptions}
                      </Select>
                      <span style={{ color: '#868181' }}>
                        {this.props.t('special-license-plates.create.multi.correct-modal.required-center-entity"')}
                      </span>
                    </FormItem>
                  )}
                  <FormItem>
                    <Button
                      htmlType="submit"
                      type="success"
                    >
                      {this.props.t('special-license-plates.create.multi.correct-modal.correct-button"')}
                    </Button>
                  </FormItem>
                </Form>
              </Modal>
            ) : null}
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(SpecialLicensePlatesCreate);
