import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from 'components/Card.jsx';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, notification, Table, Breadcrumb, Modal, Form, Input, Space, Select } from 'antd';
import SweetAlert from 'react-bootstrap-sweetalert';

import { deleteZoneAuthorization, getAllZonesIlla, getZoneAuthorizationsByUsername } from 'util/APIUtils';
import { MdAdd, MdDownload, MdPeople } from 'react-icons/md';
import Button from 'antd-button-color';
import { FaCircle } from 'react-icons/fa';
import { updateZoneAuthorizationValidity } from 'repository/authoritzations/ZoneAuthorizationsRepository';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ButtonLink from 'components/Buttons/ButtonLink';
import moment from 'moment';
import colors from 'components/Buttons/colors';
import { TbTrashXFilled } from 'react-icons/tb';

const Validity = {
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  PENDING: 'PENDING',
};

class ZoneAuthoritzationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centername: localStorage.getItem('center'),
      username: this.props.location.state.username,
      loading: true,

      modalVisible: false,
      modalName: undefined,
      modalSurnames: undefined,
      modalDni: undefined,
      modalReason: undefined,
      modalZoneId: undefined,
    };

    this.columns = [
      {
        title: this.props.t('zone-authorizations.admin.list.table.status'),
        dataIndex: 'validity',
        key: 'validity',
        align: 'center',
        width: '20%',
        render: (_, record) => {
          return (
            <Select
              value={record.validity}
              size="large"
              style={{ width: 'auto', borderRadius: '50px' }}
              onChange={(value) => this.updateValidity(record, value)}
            >
              <Select.Option key={Validity.APPROVED}>
                <FaCircle style={{ color: '#28a745' }} /> {this.props.t('zone-authorizations.validity.approved')}
              </Select.Option>
              <Select.Option key={Validity.PENDING}>
                <FaCircle style={{ color: 'orange' }} /> {this.props.t('zone-authorizations.validity.pending')}
              </Select.Option>
              <Select.Option key={Validity.DENIED}>
                <FaCircle style={{ color: 'red' }} /> {this.props.t('zone-authorizations.validity.denied')}
              </Select.Option>
            </Select>
          );
        },
      },
      {
        title: this.props.t('zone-authorizations.admin.list.table.zone'),
        dataIndex: 'zoneName',
        key: 'zoneName',
        align: 'center',
      },
      {
        title: this.props.t('zone-authorizations.admin.list.table.created-at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
        defaultSortOrder: 'descend',
        align: 'center',
        render: (text, record) => {
          if (record.createdAt !== undefined && record.createdAt !== null)
            return moment(record.createdAt).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('zone-authorizations.admin.list.table.reason'),
        dataIndex: 'reason',
        align: 'center',
      },
      {
        title: this.props.t('zone-authorizations.admin.list.table.delete'),
        dataIndex: 'eliminar',
        align: 'center',
        render: (text, record) => {
          return (
            <ButtonLink
              color="black"
              onClick={() => {
                this.setState({ modalVisible: true, modalZoneId: record.zoneId });
              }}
            >
              <TbTrashXFilled style={{ fontSize: '30' }} />
            </ButtonLink>
          );
        },
      },
    ];

    const fileColumn = {
      title: 'Fitxer del formulari associat',
      dataIndex: 'attachedFile',
      key: 'attachedFile',
      align: 'center',
      render: (_, record) => {
        const formattedDate = moment(record.createdAt).format('YYYY.MM.DD_HH.mm.ss');

        return (
          <Button
            type="primary"
            style={{ backgroundColor: colors['blue'].main }}
            onClick={() => {
              const username = this.state.username;
              const link = `https://webilla.vic.cat/ftpilla/${username}/${username}_${formattedDate}.zip`;
              window.location.href = link;
            }}
          >
            <MdDownload style={{ fontSize: '25' }} />
          </Button>
        );
      },
    };

    // Add file column to VIC center
    if (localStorage.getItem('center').toUpperCase() === 'VIC') {
      this.columns = [...this.columns, fileColumn];
    }

    this.hideAlert = this.hideAlert.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
  }

  componentWillMount() {
    this.getZoneAuthorizationsFromUser();
  }

  getZoneAuthorizationsFromUser() {
    getZoneAuthorizationsByUsername(this.props.location.state.username)
      .then((zoneAuthorizationsResponse) => {
        getAllZonesIlla(this.state.centername)
          .then((zonesIllaResponse) => {
            let zoneAuthorizations = zoneAuthorizationsResponse.map((zoneAuthorization) => {
              let zoneName = '';
              zonesIllaResponse.forEach((zone) => {
                if (zone.id === zoneAuthorization.zoneId) zoneName = zone.name;
              });

              return {
                zoneName,
                ...zoneAuthorization,
              };
            });
            this.setState({
              zoneAuthorizations,
              loading: false,
            });
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  async updateValidity(zoneAuthorization, value) {
    updateZoneAuthorizationValidity(this.state.centername, zoneAuthorization.id, value).then(() => {
      this.setState({ loading: true });
      this.getZoneAuthorizationsFromUser();
    });
    notification['success']({
      message: 'ZinkinData Portal',
      description: this.props.t('zone-authorizations.admin.list.success-update'),
    });
  }

  deleteZoneAuthorizationWarning() {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('zone-authorizations.admin.list.delete-modal.title')}
          onConfirm={() => this.removeZoneAuthorization(this.state.modalZoneId)}
          onCancel={() => this.cancelDelete()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText={this.props.t('zone-authorizations.admin.list.delete-modal.confirm-button')}
          cancelBtnText={this.props.t('zone-authorizations.admin.list.delete-modal.cancel-button')}
          showCancel
        >
          {this.props.t('zone-authorizations.admin.list.delete-modal.message')}
        </SweetAlert>
      ),
    });
  }

  cancelDelete() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block' }}
          title={this.props.t('zone-authorizations.admin.list.cancel-modal.title')}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.props.t('zone-authorizations.admin.list.cancel-modal.message')}
        </SweetAlert>
      ),
    });
  }

  validateDni(dni) {
    return /^\d{8}[a-zA-Z]$/.test(dni);
  }

  validateForm() {
    return (
      this.state.modalName !== null &&
      this.state.modalName !== undefined &&
      this.state.modalSurnames !== null &&
      this.state.modalSurnames !== undefined &&
      this.state.modalDni !== null &&
      this.state.modalDni !== undefined &&
      this.state.modalReason !== null &&
      this.state.modalReason !== undefined
    );
  }

  removeZoneAuthorization(zoneId) {
    if (this.validateDni(this.state.modalDni)) {
      if (this.validateForm()) {
        const deleteRequestBody = {
          username: this.state.modalName,
          surnames: this.state.modalSurnames,
          dni: this.state.modalDni,
          deleteReason: this.state.modalReason,
        };
        deleteZoneAuthorization(zoneId, this.state.username, deleteRequestBody)
          .then(() => {
            this.hideAlert();
            this.setState({ modalVisible: false });
            notification['success']({
              message: 'ZinkinData Portal',
              description: this.props.t('zone-authorizations.admin.list.success-delete'),
            });
            this.getZoneAuthorizationsFromUser();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.hideAlert();
        notification['error']({
          message: 'ZinkinData Portal',
          description: this.props.t('zone-authorizations.admin.list.required-fields'),
        });
      }
    } else {
      this.hideAlert();
      notification['error']({
        message: 'ZinkinData Portal',
        description: this.props.t('zone-authorizations.admin.list.bad-dni'),
      });
    }
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  onUsernameChange(e) {
    this.setState({
      modalName: e.target.value,
    });
  }

  onSurnamesChange(e) {
    this.setState({
      modalSurnames: e.target.value,
    });
  }

  onDNIChange(e) {
    this.setState({
      modalDni: e.target.value,
    });
  }

  onDeleteResonChange(e) {
    this.setState({
      modalReason: e.target.value,
    });
  }

  render() {
    if (this.props.currentUser.role !== 'ROLE_ADMIN' && this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );
      const handleCancel = () =>
        this.setState({ modalVisible: false, modalName: null, modalSurnames: null, modalDni: null, modalReason: null });
      const handleOK = () => this.deleteZoneAuthorizationWarning();

      return (
        <div className="main-content">
          {this.state.alert}
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Breadcrumb
                    separator=">"
                    style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
                  >
                    <Breadcrumb.Item
                      className="menu-history"
                      onClick={() => this.props.history.push('/users')}
                    >
                      <div style={{ display: 'inline-block', height: '30px' }}>
                        <i
                          style={{ lineHeight: '30px', verticalAlign: 'middle', marginRight: '5px', fontSize: '30px' }}
                        >
                          <MdPeople />
                        </i>
                        {this.props.t('sidebar.citizen')}
                      </div>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{this.props.t('sidebar.zone-authorizations')}</Breadcrumb.Item>
                  </Breadcrumb>

                  <Card
                    content={
                      <div>
                        <legend>
                          {' '}
                          {this.props.t('zone-authorizations.admin.list.title')}
                          {this.state.username}
                        </legend>
                        <ButtonPrimary
                          color="blue"
                          shape="round"
                          size="large"
                          style={{
                            margin: '10px 0 20px 0',
                          }}
                          onClick={() =>
                            this.props.history.push({
                              pathname: '/authoritzations-create',
                              state: { username: this.state.username },
                            })
                          }
                        >
                          {this.props.t('zone-authorizations.admin.list.add-button')}
                          <MdAdd
                            style={{
                              verticalAlign: 'sub',
                              fontSize: '20',
                              marginLeft: '5px',
                            }}
                          />
                        </ButtonPrimary>

                        <Table
                          dataSource={this.state.zoneAuthorizations}
                          columns={this.columns}
                          rowKey={(record) => record.zoneId}
                          pagination={{ pageSize: 5, showSizeChanger: false }}
                        />
                      </div>
                    }
                  />
                </Spin>
              </Col>
            </Row>
          </Grid>

          <Modal
            title={this.props.t('zone-authorizations.admin.list.delete-modal-form.title')}
            open={this.state.modalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <div style={{ marginBottom: '20px' }}>
              <p style={{ fontWeight: 'bold', color: 'red' }}>
                {this.props.t('zone-authorizations.admin.list.delete-modal-form.description-1')}
              </p>
              <p>{this.props.t('zone-authorizations.admin.list.delete-modal-form.description-2')}</p>
            </div>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <Form.Item
                label={this.props.t('zone-authorizations.admin.list.delete-modal-form.name')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: this.props.t('zone-authorizations.admin.list.delete-modal-form.required-name'),
                  },
                ]}
              >
                <Input
                  value={this.state.modalName}
                  onChange={(event) => this.onUsernameChange(event)}
                />
              </Form.Item>

              <Form.Item
                label={this.props.t('zone-authorizations.admin.list.delete-modal-form.surnames')}
                name="surname"
                rules={[
                  {
                    required: true,
                    message: this.props.t('zone-authorizations.admin.list.delete-modal-form.required-surnames'),
                  },
                ]}
              >
                <Input
                  value={this.state.modalSurnames}
                  onChange={(event) => this.onSurnamesChange(event)}
                />
              </Form.Item>

              <Form.Item
                label={this.props.t('zone-authorizations.admin.list.delete-modal-form.dni')}
                name="dni"
                rules={[
                  {
                    required: true,
                    message: this.props.t('zone-authorizations.admin.list.delete-modal-form.required-dni'),
                  },
                ]}
              >
                <Input
                  value={this.state.modalDni}
                  onChange={(event) => this.onDNIChange(event)}
                />
              </Form.Item>

              <Form.Item
                name={this.props.t('zone-authorizations.admin.list.delete-modal-form.reason')}
                label="Motiu"
                rules={[
                  {
                    required: true,
                    message: this.props.t('zone-authorizations.admin.list.delete-modal-form.required-reason'),
                  },
                ]}
              >
                <Input.TextArea
                  value={this.state.modalReason}
                  onChange={(event) => this.onDeleteResonChange(event)}
                />
              </Form.Item>

              <Form.Item>
                <Space>
                  <ButtonPrimary
                    color="black"
                    htmlType="submit"
                    danger
                    onClick={handleOK}
                  >
                    {this.props.t('zone-authorizations.admin.list.delete-modal-form.confirm-button')}
                  </ButtonPrimary>
                  <Button
                    type="primary"
                    onClick={handleCancel}
                  >
                    {this.props.t('zone-authorizations.admin.list.delete-modal-form.cancel-button')}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      );
    }
  }
}

export default withTranslation('common')(ZoneAuthoritzationsList);
