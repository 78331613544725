import { API_BASE_URL, buildOptionsWithAuth, buildOptionsWithoutAuth } from 'util/Constants.js';

export function login(loginRequest) {
  const url = `${API_BASE_URL}/auth/signin`;
  const headers = buildOptionsWithoutAuth('POST');

  return fetch(url, Object.assign({}, headers, { body: JSON.stringify(loginRequest) }))
    .then((response) =>
      response.ok
        ? response.json().then((json) => {
            if (!response.ok) {
              return Promise.reject(json);
            }
            return json;
          })
        : response.status === 409
        ? response.text().then((json) => Promise.reject(json))
        : response
    )
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function getCenter(name) {
  const url = `${API_BASE_URL}/centers/${name}`;
  const headers = buildOptionsWithAuth('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function verifyMfa(userId, code) {
  const url = `${API_BASE_URL}/auth/mfa/${userId}?code=${code}`;
  const headers = buildOptionsWithoutAuth('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error(response.status);
}

export async function sendVerificationCode(userEmail, code, centerName) {
  const url = `${API_BASE_URL}/users/verify/send-verification-code?center-name=${centerName}&user-email=${userEmail}&code=${code}`;
  const options = buildOptionsWithoutAuth('POST');

  let response;

  try {
    response = await fetch(url, options);
  } catch (error) {
    throw new Error(error);
  }

  if (response.status !== 200) {
    throw new Error(response.status);
  }
}
