import { API_BASE_URL, buildOptionsWithAuth } from "util/Constants.js";

export function deleteSpecialLicensePlate(centerName, specialLicensePlate, specialLicensePlateId) {

  const url = `${API_BASE_URL}/special-license-plates?center=${centerName}&license-plate=${specialLicensePlate}&license-plate-id=${specialLicensePlateId}`;
  const headers = buildOptionsWithAuth("DELETE");

  return fetch(url, headers)
    .then((response) => {
      if (response.status !== 200)
        throw new Error("Error intern de servidor");
    })
}

export function postSpecialLicensePlate(licensePlate, center, centerEntityId) {
  const url = `${API_BASE_URL}/special-license-plates?license-plate=${licensePlate}&center=${center}&center-entity-id=${centerEntityId}`;
  const headers = buildOptionsWithAuth("POST");

  return fetch(url, headers)
    .then((response) => {
      if (response.status !== 201) {
        if (response.status === 409)
          throw new Error("Ja existeix una matricula especial d'aquesta entitat");
        else
          throw new Error("Error intern de servidor");
      }
    })
}

export function getSpecialLicensePlatesFiltered(licensePlate, center, centerEntityName){
  var path_licensePlate = "";
  var path_centerEntityName = "";

  if(licensePlate !== undefined){
    path_licensePlate = "&license-plate=" + licensePlate;
  }

  if(centerEntityName !== undefined){
    path_centerEntityName = "&center-entity-name=" + centerEntityName;
  }

  const url = `${API_BASE_URL}/special-license-plates?center=${center}` + path_licensePlate + path_centerEntityName;

  const headers = buildOptionsWithAuth("GET");

  return fetch(url, headers)
    .then((response) => {
      if (response.status !== 200)
        throw new Error("Error intern de servidor");
      return response.json();
    })
}

export function getAllSpecialLicensePlatesFromCenter(centername) {
  const url = `${API_BASE_URL}/special-license-plates?center=${centername}`;
  const headers = buildOptionsWithAuth("GET");

  return fetch(url, headers)
    .then((response) => {
      if (response.status !== 200)
        throw new Error("Error intern de servidor");
      return response.json();
    })
}

export function postSpecialLicensePlateList(centername, list){
  const url = `${API_BASE_URL}/special-license-plates/list?center=${centername}`;
  const headers = buildOptionsWithAuth("POST");

  return fetch(url, Object.assign({}, headers, { body: JSON.stringify(list) }))
    .then((response) => {
      if (response.status !== 201)
        throw new Error("Error intern de servidor");
    })
}