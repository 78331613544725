import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from 'components/Card.jsx';
import LoginForm from 'views/Login/LoginForm';
import { withTranslation } from 'react-i18next';

import logo from 'assets/img/ZinkinData_100_25.png';
import logoVic from 'assets/img/vic_new.png';
import logoTorello from 'assets/img/logo_AjT_ALT_marro.png';
import i18next from 'i18next';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      centerLogo: logo,
      userFound: false,
    };
  }

  setManifest(centerName) {
    var domain = document.location.origin;
    var currentUrl = window.location.href;
    var myDynamicManifest = {
      short_name: 'ZinkinData Portal ' + centerName,
      name: 'ZinkinData Portal ' + centerName,
      description: 'ZinkinData Portal ' + centerName,
      icons: [
        {
          src: domain + '/512.png',
          sizes: '512x512',
          type: 'image/png',
        },
      ],
      start_url: currentUrl,
      display: 'standalone',
      theme_color: '#000000',
      background_color: '#ffffff',
    };
    const stringManifest = JSON.stringify(myDynamicManifest);

    const blob = new Blob([stringManifest], { type: 'application/javascript' });

    const manifestURL = URL.createObjectURL(blob);
    document.getElementById('manifest').setAttribute('href', manifestURL);
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );

    var pathVariables = this.props.history.location.pathname.split('/');
    var center = pathVariables[pathVariables.length - 1];
    // localStorage.removeItem("accessToken");
    if (center !== 'login') localStorage.setItem('center', center.toLowerCase());
    else localStorage.setItem('center', 'nexotech');

    try {
      switch (center.toLowerCase()) {
        case 'torello':
          this.setManifest('Torello');
          this.setState({ centerLogo: logoTorello });
          break;
        case 'vic':
          this.setManifest('Vic');
          this.setState({ centerLogo: logoVic });
          break;
        case 'rister':
          this.setManifest('Rister');
          this.setState({ centerLogo: 'https://rister.com/img/ristercom-logo-1568124729.jpg' });
          break;
        default:
          this.setManifest('Nexotech');
          break;
      }
    } catch (error) {}

    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    const tld = parts.length > 1 ? parts[parts.length - 1] : '';

    switch (tld) {
      case 'cat':
        i18next.changeLanguage('ca');
        break;
      case 'es':
        i18next.changeLanguage('es');
        break;
      case 'eus':
        i18next.changeLanguage('eus');
        break;
      case 'gal':
        i18next.changeLanguage('ga');
        break;
      default:
        i18next.changeLanguage('es');
        break;
    }
  }
  render() {
    return (
      <Grid>
        <Row>
          <Col
            md={4}
            sm={6}
            mdOffset={4}
            smOffset={3}
          >
            <Card
              hidden={this.state.cardHidden}
              textCenter
              content={
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      style={{ maxWidth: '70%' }}
                      alt="Logo ZinkiData"
                      src={this.state.centerLogo}
                    ></img>
                  </div>
                  <div style={{ margin: '25px 0 -20px 0px' }}>
                    <LoginForm
                      onLogin={this.props.onLogin}
                      onChangePassword={this.props.onChangePassword}
                      {...this.props}
                    />
                  </div>
                </div>
              }
              legend={<div />}
              ftTextCenter
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withTranslation('common')(LoginPage);
