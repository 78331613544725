import React from "react";
import { withTranslation } from "react-i18next";

const AccessDenied = () => (
  <div>
    <h1 style={{ fontWeight: "bold", paddingLeft: "50px" }}>
      ACCESS DENIED
    </h1>
  </div>
);

export default withTranslation('common')(AccessDenied);
