export const ReasonsEnum = Object.freeze({
  PROPIETARI_HABITATGE: "Soc propietari d'un habitatge de dins de l'illa de vianants",
  PROPIETARI_LOCAL: "Soc propietari d'un local de dins de l'illa de vianants",
  PROPIETARI_GARATGE: "Soc propietari d'un garatge de dins de l'illa de vianants",
  RESIDENT_EMPADRONAT: "Soc resident o llogater d'un habitatge on hi estic empadronat",
  LLOGATER_NO_EMPADRONAT: "Soc llogater d'un local, d'un garatge o d'un habitatge on no hi estic empadronat",
  // MAJOR_75_ANY_EMPADRONAT: "Soc major de 75 anys empadronat a Vic",
  ESTABLIMENT_LLICENCIA: "Tinc un establiment amb llicència municipal d'activitats a Vic",
  ACCES_CAUSAL: 'Necessito un accés causal (accés màxim de 3 mesos)',
  MOBILITAT_REDUIDA: 'Tinc mobilitat reduïda acreditada',
});

export const ZonesEnum = [
  { id: 23, name: 'Centre històric' },
  { id: 24, name: 'Eixample Morató' },
  { id: 25, name: 'Carrer de Gurb' },
  { id: 7, name: 'El Portalet' },
];

export const RelationshipsEnum = Object.freeze({
  TITULAR_VEHICLE: 'Soc titular del vehicle',
  CONDUCTOR_HABITUAL: 'No soc titular del vehicle però en soc conductor habitual',
  VEHICLE_RENTING: 'El vehicle està en règim de renting, lising o lloguer amb contracte superior a 3 mesos',
  CAP_ANTERIOR: 'Cap de les anteriors',
});
