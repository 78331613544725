import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Spin, notification, Breadcrumb, Select, Card, Form, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { postCenterEntity } from 'repository/centerEntity/CenterEntityRepository';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

import moment from 'moment';
import 'moment/locale/es';
import { HiOfficeBuilding } from 'react-icons/hi';
moment.locale('es');

class CreateCenterEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      centername: localStorage.getItem('center'),
    };
  }

  render() {
    const onFinish = (values) => {
      this.setState({ loading: true });

      postCenterEntity(values.name, this.state.centername, values.option)
        .then(() => {
          notification['success']({
            message: 'ZinkinData Portal',
            description: this.props.t('center-entities.create.success-message'),
          });

          this.setState({ loading: false });
          this.props.history.push('/center-entities');
        })
        .catch((error) => {
          this.setState({ loading: false });
          notification['error']({
            message: 'ZinkinData Portal',
            description: error.message,
          });
        });
    };

    if (this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      return (
        <div className="main-content">
          {this.state.alert}
          <Grid fluid>
            <Breadcrumb
              separator=">"
              style={{ marginBottom: '10px', fontSize: 20 }}
            >
              <Breadcrumb.Item
                className="menu-history"
                onClick={() => this.props.history.push('/center-entities')}
              >
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i style={{ lineHeight: '30px', verticalAlign: 'middle', marginRight: '5px', fontSize: '30px' }}>
                    <HiOfficeBuilding />
                  </i>
                  {this.props.t('center-entities.common-breadcrumb')}
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{this.props.t('center-entities.create.breadcrumb')}</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Card title={this.props.t('center-entities.create.legend')}>
                    <Form
                      layout="vertical"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name={'name'}
                        label={this.props.t('center-entities.create.name-field')}
                        rules={[
                          {
                            required: true,
                            message: this.props.t('center-entities.create.required-name'),
                          },
                        ]}
                      >
                        <Input
                          style={{ maxWidth: '500px' }}
                          size="large"
                          placeholder={this.props.t('center-entities.create.name-field-placeholder')}
                        />
                      </Form.Item>
                      <Form.Item
                        name={'option'}
                        label={this.props.t('center-entities.create.opposite-direction-field')}
                        rules={[
                          {
                            required: true,
                            message: this.props.t('center-entities.create.required-opposite-direction'),
                          },
                        ]}
                      >
                        <Select
                          style={{ maxWidth: '200px' }}
                          size="large"
                          placeholder={this.props.t('center-entities.create.required-opposite-direction')}
                          options={[
                            {
                              label: this.props.t('center-entities.create.option-yes'),
                              value: '1',
                            },
                            {
                              label: this.props.t('center-entities.create.option-no'),
                              value: '0',
                            },
                          ]}
                        />
                      </Form.Item>
                      <ButtonPrimary
                        color="blue"
                        shape="round"
                        size="large"
                        htmlType="submit"
                      >
                        {this.props.t('center-entities.create.create-button')}
                      </ButtonPrimary>
                    </Form>
                  </Card>
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(CreateCenterEntities);
