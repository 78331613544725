import { notification } from 'antd';
import React, { Component } from 'react';
import { Nav, MenuItem } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import colors from 'components/Buttons/colors';
import { MdOutlineTransitEnterexit } from 'react-icons/md';

class HeaderLinks extends Component {
  render() {
    return (
      <div>
        <Nav
          pullRight
          bsClass="hide-mobile"
        >
          <MenuItem>
            <div
              onClick={() => {
                this.props.handleLogout();
                notification['success']({
                  message: 'ZinkinData Portal',
                  description: this.props.t('header.exit-message'),
                });
              }}
              style={{ color: colors.blue.main, display: 'flex', alignItems: 'center', marginRight: 5 }}
            >
              <MdOutlineTransitEnterexit style={{ fontSize: 20 }} /> {this.props.t('header.exit')}
            </div>
          </MenuItem>
        </Nav>
      </div>
    );
  }
}
export default withTranslation('common')(HeaderLinks);
