import React from 'react';

const CustomAvatar = ({ src, alt = 'avatar' }) => {
  const avatarContainerStyle = {
    width: `110px`,
    height: `110px`,
    borderRadius: '5%', // Asegura que el contenedor sea circular
    overflow: 'hidden', // Esconde cualquier contenido que sobrepase el contenedor
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white', // Color de fondo opcional
    padding: '3%',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    //minHeight: '100%',
    objectFit: 'contain',
  };

  return (
    <div style={avatarContainerStyle}>
      <img
        src={src}
        alt={alt}
        style={imageStyle}
      />
    </div>
  );
};

export default CustomAvatar;
