import React, { useState } from 'react';
import { Button, Flex, Input, Modal, Radio, Space } from 'antd';
import { checkEmail, checkUsername } from 'repository/accessForm/AccessFormRepository';
import { validateIdentificationNumber } from 'util/validation';

const FormInput = ({ label, required, name, placeholder, value, error, onChange }) => (
  <Flex vertical gap="small">
    <label>
      {required && <span style={{ color: 'red' }}>* </span>}
      {label}:
    </label>
    <Input name={name} placeholder={placeholder} value={value} onChange={onChange} />
    {error && <div style={{ color: 'red' }}>{error}</div>}
  </Flex>
);

export const PersonalDataStep = ({ initialFormValues, submitFormFields, nextStep }) => {
  const [documentType, setDocumentType] = useState('dni');
  const [formValues, setFormValues] = useState({
    cardId: initialFormValues.cardId || '',
    name: initialFormValues.name || '',
    surnames: initialFormValues.surnames || '',
    email: initialFormValues.email || '',
    confirmEmail: initialFormValues.confirmEmail || '',
    phone: initialFormValues.phone || '',
    confirmPhone: initialFormValues.confirmPhone || '',
  });
  const [formErrors, setFormErrors] = useState({
    cardId: null,
    name: null,
    surnames: null,
    email: null,
    confirmEmail: null,
    phone: null,
    confirmPhone: null,
  });

  const validateField = (name, value) => {
    const setError = (message) => {
      setFormErrors((prev) => ({ ...prev, [name]: message }));
    };

    let error = true;

    switch (name) {
      case 'cardId':
        if (!value) {
          setError('Si us plau, introduïu el DNI, NIF, NIE, CIF o passaport');
        } else if (documentType === 'passport' && !/^[a-zA-Z0-9]+$/.test(value)) {
          setError('El passaport només pot contenir caràcters alfanumèrics');
        } else if (documentType !== 'passport' && !validateIdentificationNumber(value)) {
          setError("El document d'identificació no segueix el patró de DNI, NIF, NIE, CIF");
        } else {
          setError(null);
          error = false;
        }
        break;
      case 'name':
        if (!value) {
          setError('Si us plau, introduïu el nom');
        } else if (value.length < 2 || value.length > 30) {
          setError('El nom ha de tenir entre 2 i 30 caràcters');
        } else {
          setError(null);
          error = false;
        }
        break;
      case 'surnames':
        if (!value) {
          setError('Si us plau, introduïu els cognoms');
        } else if (value.length < 2 || value.length > 60) {
          setError('Els cognoms han de tenir entre 2 i 60 caràcters');
        } else {
          setError(null);
          error = false;
        }
        break;
      case 'email':
        if (!value) {
          setError('Si us plau, introduïu el correu electrònic');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          setError('No és un correu electrònic vàlid');
        } else {
          setError(null);
          error = false;
        }
        break;
      case 'confirmEmail':
        if (!value) {
          setError('Si us plau, introduïu el correu electrònic una altra vegada');
        } else if (formValues.email !== value) {
          setError('Els correus electrònics no coincideixen');
        } else {
          setError(null);
          error = false;
        }
        break;
      case 'phone':
        if (value && !/^\d+$/.test(value)) {
          setError('No és un telèfon vàlid');
        } else if (value.length > 15) {
          setError('El telèfon mòbil és massa llarg');
        } else {
          setError(null);
          error = false;
        }
        break;
      case 'confirmPhone':
        if (formValues.phone !== value) {
          setError('Els telèfons no coincideixen');
        } else {
          setError(null);
          error = false;
        }
        break;
      default:
        error = false;
        break;
    }

    return error;
  };

  const onFinish = async () => {
    const errors = Object.entries(formValues).map(([key, value]) => {
      return validateField(key, value);
    });

    if (errors.some((error) => error === true)) {
      return;
    }

    try {
      await checkEmail(formValues.email, 'vic');
      await checkUsername(formValues.cardId, 'vic');
      submitFormFields(formValues);
      nextStep();
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: <div>{error.message}</div>,
        okText: 'Ok',
        centered: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    validateField(name, value);
  };

  const formStyle = {
    width: '100%',
    maxWidth: '500px',
  };

  return (
    <Flex style={formStyle} vertical gap="middle">
      <Space direction="vertical" style={{ marginBottom: '15px' }}>
        <div style={{ fontWeight: 'bold' }}>
          <span style={{ color: 'red' }}>* </span>Indiqueu el tipus de document
        </div>
      </Space>
      <Radio.Group onChange={(e) => setDocumentType(e.target.value)} value={documentType}>
        <Radio value="dni" style={{ fontWeight: '400', marginBottom: '20px' }}>
          DNI, NIF, NIE, CIF
        </Radio>
        <Radio value="passport" style={{ fontWeight: '400' }}>
          Passaport
        </Radio>
      </Radio.Group>

      <FormInput
        label="Número del document d'identificació"
        placeholder={documentType === 'passport' ? 'Introdueix el passaport' : 'Introdueix el DNI, NIF, NIE, CIF'}
        required
        name="cardId"
        value={formValues.cardId}
        error={formErrors.cardId}
        onChange={handleChange}
      />

      <FormInput
        label="Nom"
        placeholder="Introdueix el nom"
        required
        name="name"
        value={formValues.name}
        error={formErrors.name}
        onChange={handleChange}
      />

      <FormInput
        label="Cognoms"
        placeholder="Introdueix els cognoms"
        required
        name="surnames"
        value={formValues.surnames}
        error={formErrors.surnames}
        onChange={handleChange}
      />

      <FormInput
        label="Correu electrònic"
        placeholder="Introdueix el correu electrònic"
        required
        name="email"
        value={formValues.email}
        error={formErrors.email}
        onChange={handleChange}
      />

      <FormInput
        label="Repetiu el correu electrònic"
        placeholder="Repeteix el correu electrònic"
        required
        name="confirmEmail"
        value={formValues.confirmEmail}
        error={formErrors.confirmEmail}
        onChange={handleChange}
      />

      <FormInput
        label="Telèfon mòbil"
        name="phone"
        placeholder="Introdueix el número de telèfon"
        value={formValues.phone}
        error={formErrors.phone}
        onChange={handleChange}
      />

      <FormInput
        label="Repetiu el telèfon mòbil"
        name="confirmPhone"
        placeholder="Repeteix el número de telèfon"
        value={formValues.confirmPhone}
        error={formErrors.confirmPhone}
        onChange={handleChange}
      />

      <div>
        <Button type="primary" style={{ width: 'auto', float: 'right' }} onClick={onFinish}>
          Següent
        </Button>
      </div>
    </Flex>
  );
};
