import React, { Component } from 'react';
import ChangePasswordForm from 'views/Login/ChangePasswordForm';
import { Alert, Card, Space } from 'antd';

import { withTranslation } from 'react-i18next';

class ChangePasswordPage extends Component {
  render() {
    return (
      <Space
        align="center"
        direction="vertical"
        style={{ width: '100%', marginTop: '5%' }}
      >
        <Card
          title={this.props.t('change-password-page.title')}
          size="large"
          style={{ width: '500px' }}
        >
          <Alert
            message={this.props.t(this.props.location.state.title)}
            description={this.props.t(this.props.location.state.description)}
            type="info"
            showIcon
            style={{ marginBottom: '3%' }}
          />
          <ChangePasswordForm updatedPassword={this.props.updatedPassword} />
        </Card>
      </Space>
    );
  }
}

export default withTranslation('common')(ChangePasswordPage);
