const colors = Object.freeze({
  blue: {
    main: '#0064ad',
    lighter: '#258eda',
  },
  black: {
    main: '#343a40',
    lighter: '#646b71',
  },
  grey: {
    main: '#9d9d9c',
    lighter: '#c8c8c8',
  },
  white: {
    main: '#ddd',
    lighter: '#eee',
  },
  red: {
    main: '#f40f02',
    lighter: '#ff6552',
  },
  green: {
    main: '#1d6f42',
    lighter: '#4caa73',
  },
});

export default colors;
