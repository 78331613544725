import { API_BASE_URL, ACCESS_TOKEN, buildOptionsWithAuth, buildOptionsWithoutAuth } from 'util/Constants.js';

export async function createUser(user) {
  const userJson = {
    id: user.id,
    username: user.username,
    name: user.name,
    surnames: user.surnames,
    email: user.email,
    phone: user.phone,
    reducedMobility: user.reducedMobility,
  };

  const url = `${API_BASE_URL}/admin/users?center=${localStorage.getItem('center')}`;
  const headers = buildOptionsWithAuth('POST');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(userJson) }));
  if (response.status !== 201) {
    throw new Error('Error intern de servidor');
  }
}

export async function createAdminUser(id, username, name, surnames, email, centername) {
  const userJson = {
    id: id,
    username: username,
    name: name,
    surnames: surnames,
    email: email,
  };

  const url = `${API_BASE_URL}/superadmin/${centername}/users/role-admin`;
  const headers = buildOptionsWithAuth('POST');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(userJson) }));
  if (response.status !== 201) {
    throw new Error('Error intern de servidor');
  }
}

export async function changeLanguage(centerName, language) {
  const url = `${API_BASE_URL}/users/${centerName}/language?lan=${language}`;
  const headers = buildOptionsWithAuth('PUT');
  const response = await fetch(url, headers);
  if (response.status !== 200) {
    throw new Error('Internal error');
  }
}

export async function changeMfa(centerName, mfa) {
  const url = `${API_BASE_URL}/users/${centerName}/mfa?value=${mfa}`;
  const headers = buildOptionsWithAuth('PUT');
  const response = await fetch(url, headers);
  if (response.status !== 200) {
    throw new Error('Internal error');
  }
}

export async function deleteUserByUsername(username) {
  const url = `${API_BASE_URL}/admin/users?username=${username}&center=${localStorage.getItem('center')}`;
  const headers = buildOptionsWithAuth('DELETE');
  const response = await fetch(url, headers);
  if (response.status !== 204) {
    throw new Error('Internal error');
  }
}

export async function updatePassword(password) {
  const userJson = {
    password: password,
    center: localStorage.getItem('center'),
  };

  const url = `${API_BASE_URL}/users`;
  const headers = buildOptionsWithAuth('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(userJson) }));
  switch (response.status) {
    case 404:
      throw new Error('error-update-password');
    case 400:
      throw new Error('same-update-password');
    default:
      return;
  }
}

export async function adminUpdatePassword(username, password) {
  const userJson = {
    password: password,
    center: localStorage.getItem('center'),
  };

  const url = `${API_BASE_URL}/admin/users/${username}/password`;
  const headers = buildOptionsWithAuth('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(userJson) }));
  if (response.status !== 200) {
    throw new Error('Error intern de servidor');
  }
}

export async function editUser(username, name, surnames, email, phone, reducedMobility) {
  const userJson = {
    name: name,
    surnames: surnames,
    password: null,
    email: email,
    phone: phone,
    reducedMobility: reducedMobility,
  };

  let url = API_BASE_URL + '/admin/users/' + username + '?center=' + localStorage.getItem('center');

  const headers = buildOptionsWithAuth('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(userJson) }));
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function editAdminUser(username, name, surnames, email, centername) {
  const headers = buildOptionsWithAuth('PUT');

  const json = {
    username: username,
    name: name,
    surnames: surnames,
    email: email,
  };

  const url = API_BASE_URL + '/superadmin/' + centername + '/users/role-admin';

  const response = await fetch(url, { ...headers, body: JSON.stringify(json) });
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject('No access token set.');
  }

  const url = `${API_BASE_URL}/users/me?center=${localStorage.getItem('center')}`;
  const headers = buildOptionsWithAuth('GET');

  return fetch(url, headers).then((response) => {
    if (response.status !== 200) throw new Error('Error intern de servidor');
    return response.json();
  });
}

export async function getUsersFromCenter(centerName, licensePlate, dni, name, surname, pendingPlates, pendingZones) {
  let licensePlate_path = '';
  let dni_path = '';
  let name_path = '';
  let surname_path = '';

  if (licensePlate !== undefined) {
    licensePlate_path = '&license-plate=' + licensePlate;
  }

  if (dni !== undefined) {
    dni_path = '&dni=' + dni;
  }

  if (name !== undefined) {
    name_path = '&name=' + name;
  }

  if (surname !== undefined) {
    surname_path = '&surname=' + surname;
  }

  const headers = buildOptionsWithAuth('GET');

  const url = `${API_BASE_URL}/admin/users?center=${centerName}&pending-zones=${pendingZones}&pending-plates=${pendingPlates}${licensePlate_path}${dni_path}${name_path}${surname_path}`;

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function getCitizenCount() {
  const url = `${API_BASE_URL}/admin/users/count?center=${localStorage.getItem('center')}`;
  const headers = buildOptionsWithAuth('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function getAdminUsersFromCenter(centerName) {
  const url = `${API_BASE_URL}/superadmin/${centerName}/users/role-admin`;
  const headers = buildOptionsWithAuth('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function setEmailToUser(centerName, email) {
  let url = `${API_BASE_URL}/${centerName}/users/email?email=${email}`;
  const headers = buildOptionsWithAuth('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function resetPassword(email, centerName) {
  const url = `${API_BASE_URL}/auth/reset-password?email=${email}&center=${centerName}`;
  const options = buildOptionsWithoutAuth('POST');

  const response = await fetch(url, options);

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error("No s'ha trobat cap usuari amb aquest email al sistema");
    }
    throw new Error(`Error codi http: ${response.status}`);
  }
}

export async function changeUserEnable(centerName, userId, enable) {
  const url = `${API_BASE_URL}/admin/users/${centerName}/${userId}/enable?value=${enable}`;
  const options = buildOptionsWithAuth('PUT');

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`Error codi http: ${response.status}`);
  }
}

export async function verifyEmail(userId, code) {
  const url = `${API_BASE_URL}/users/verify/${userId}/${code}`;
  const headers = buildOptionsWithoutAuth('POST');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function resetCredentials(centerName, userId) {
  const url = `${API_BASE_URL}/admin/${centerName}/users/${userId}/reset`;
  const headers = buildOptionsWithAuth('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}
