import LoginPage from "views/Login/LoginPage.jsx";
import FirstLoginPage from "views/Login/LoginPage.jsx";
import VerifiedEmail from "views/VerifiedEmail/VerifiedEmail";

var pagesRoutes = [
  {
    path: "/pages/login",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  {
    path: "/pages/change-password",
    name: "Change Password Page",
    mini: "CPP",
    component: FirstLoginPage
  },
  {
    path: "/pages/verify-email",
    name: "Verify Email",
    mini: "VE",
    component: VerifiedEmail
  }
];

export default pagesRoutes;
