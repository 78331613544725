import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { v4 as uuid4 } from 'uuid';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, notification, Select, Breadcrumb, Radio, Input, Form, Card, Divider, Space } from 'antd';
import { withTranslation } from 'react-i18next';

import { getAllZonesIlla } from '../../util/APIUtils.js';
import * as UserRepository from '../../repository/user/UserRepository';
import * as ZoneAuthorizationsRepository from '../../repository/authoritzations/ZoneAuthorizationsRepository';
import { MdPeople } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary.jsx';
import { validateIdentificationNumber } from 'util/validation.js';

const { TextArea } = Input;
const { Option } = Select;
class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: [
        this.props.t('citizens.create-edit.reasons.resident'),
        this.props.t('citizens.create-edit.reasons.owner'),
        this.props.t('citizens.create-edit.reasons.reduced-mobility'),
      ],
      reason: '',
      optionSelected: 1,

      zones: [],
      selectedZones: [],
      loading: true,
    };
  }

  componentDidMount() {
    getAllZonesIlla(localStorage.getItem('center'))
      .then((response) => {
        this.setState({ zones: response, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async createZoneAuth(dni, reason, zones) {
    if (zones.length > 0) {
      const requests = [];

      zones.forEach((zoneId) =>
        requests.push(ZoneAuthorizationsRepository.postZoneAuthorization(uuid4(), dni, reason, zoneId))
      );

      await Promise.all(requests);
    }
  }

  render() {
    if (this.props.currentUser.role !== 'ROLE_ADMIN' && this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const createUser = (values) => {
        var json = {
          id: uuid4(),
          username: values.dni,
          name: values.name,
          surnames: values.surnames,
          email: values.email,
          phone: values.phone,
          reducedMobility: values.reducedMobility === 'yes',
        };

        UserRepository.createUser(json)
          .then(() => {
            this.createZoneAuth(values.dni, this.state.reason, values.zones);
          })
          .then(() => {
            this.setState({ loading: false });
            notification['success']({
              message: 'ZinkinData Portal',
              description: this.props.t('citizens.create-edit.success-message'),
            });

            this.props.history.push('/users');
          })
          .catch((error) => {
            this.setState({ loading: false });

            notification['error']({
              message: 'ZinkinData Portal',
              description: error.message,
            });
          });
      };

      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );
      const zonesOptions = this.state.zones.map((zone) => <Option key={zone.id}>{zone.name}</Option>);

      return (
        <div className="main-content">
          <Grid fluid>
            <Breadcrumb
              separator=">"
              style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
            >
              <Breadcrumb.Item
                className="menu-history"
                onClick={() => this.props.history.push('/users')}
              >
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i style={{ lineHeight: '30px', verticalAlign: 'middle', marginRight: '5px', fontSize: '30px' }}>
                    <MdPeople />
                  </i>
                  {this.props.t('sidebar.citizen')}
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{this.props.t('citizens.create-edit.title')}</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Spin
                spinning={this.state.loading}
                indicator={antIcon}
              >
                <Card>
                  <Form
                    layout="vertical"
                    size="middle"
                    onFinish={createUser}
                  >
                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Form.Item
                        name={'dni'}
                        label={this.props.t('citizens.create-edit.form.dni')}
                        rules={[
                          {
                            required: true,
                            message: this.props.t('citizens.create-edit.form.errors.required-dni'),
                          },
                          () => ({
                            validator(_, value) {
                              if (validateIdentificationNumber(value)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("El document d'identificació no segueix el patro de DNI/NIF/NIE/CIF/Passaport")
                              );
                            },
                          }),
                        ]}
                        style={{
                          display: 'inline-block',
                          width: 'calc(33% - 8px)',
                        }}
                      >
                        <Input placeholder="Ex: 75382654H" />
                      </Form.Item>
                      <Form.Item
                        name={'name'}
                        label={this.props.t('citizens.create-edit.form.name')}
                        rules={[
                          {
                            required: true,
                            message: this.props.t('citizens.create-edit.form.errors.required-name'),
                          },
                          {
                            min: 3,
                            message: this.props.t('citizens.create-edit.form.errors.min-name'),
                          },
                        ]}
                        style={{
                          display: 'inline-block',
                          width: 'calc(33% - 8px)',
                          margin: '0 8px',
                        }}
                      >
                        <Input placeholder="Ex: Carles" />
                      </Form.Item>
                      <Form.Item
                        name={'surnames'}
                        label={this.props.t('citizens.create-edit.form.surnames')}
                        initialValue={''}
                        style={{
                          display: 'inline-block',
                          width: 'calc(33% - 8px)',
                          margin: '0 8px',
                        }}
                      >
                        <Input placeholder="Ex: Martí Pérez" />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Form.Item
                        name={'email'}
                        label={this.props.t('citizens.create-edit.form.email')}
                        rules={[
                          {
                            required: true,
                            message: this.props.t('citizens.create-edit.form.errors.required-email'),
                          },
                          {
                            pattern: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
                            message: this.props.t('citizens.create-edit.form.errors.pattern-email'),
                          },
                        ]}
                        style={{
                          display: 'inline-block',
                          width: 'calc(33% - 8px)',
                        }}
                      >
                        <Input placeholder="Ex: cmartiperez@gmail.com" />
                      </Form.Item>
                      <Form.Item
                        name={'phone'}
                        label={this.props.t('citizens.create-edit.form.phone')}
                        style={{
                          display: 'inline-block',
                          width: 'calc(33% - 8px)',
                          margin: '0 8px',
                        }}
                      >
                        <Input placeholder="Ex: 935656981" />
                      </Form.Item>
                      <Form.Item
                        name={'reducedMobility'}
                        label={this.props.t('citizens.create-edit.form.reduced-mobility')}
                        required
                        style={{
                          display: 'inline-block',
                          width: 'calc(33% - 8px)',
                          margin: '0 8px',
                        }}
                      >
                        <Select
                          initialValue={'no'}
                          options={[
                            {
                              value: 'yes',
                              label: this.props.t('citizens.create-edit.form.yes'),
                            },
                            {
                              value: 'no',
                              label: this.props.t('citizens.create-edit.form.no'),
                            },
                          ]}
                        />
                      </Form.Item>
                    </Form.Item>
                    <Divider orientation="left">{this.props.t('citizens.create-edit.form.zone-authorization')}</Divider>
                    <Form.Item>
                      <Form.Item
                        name={'reason'}
                        style={{
                          display: 'inline-block',
                          width: 'calc(50% - 8px)',
                        }}
                      >
                        <Radio.Group
                          onChange={(event) => {
                            this.setState({
                              optionSelected: event.target.value,
                              reason: event.target.value,
                            });
                          }}
                        >
                          <Space direction="vertical">
                            <Radio
                              style={{ fontWeight: 'normal' }}
                              value={this.state.reasons[0]}
                            >
                              {this.state.reasons[0]}
                            </Radio>
                            <Radio
                              style={{ fontWeight: 'normal' }}
                              value={this.state.reasons[1]}
                            >
                              {this.state.reasons[1]}
                            </Radio>
                            <Radio
                              style={{ fontWeight: 'normal' }}
                              value={this.state.reasons[2]}
                            >
                              {this.state.reasons[2]}
                            </Radio>
                            <Radio
                              style={{ fontWeight: 'normal' }}
                              value={4}
                            >
                              Altres...
                            </Radio>
                            {this.state.optionSelected === 4 ? (
                              <TextArea
                                onChange={(event) =>
                                  this.setState({
                                    reason: event.target.value,
                                  })
                                }
                              />
                            ) : null}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        name={'zones'}
                        label={this.props.t('citizens.create-edit.form.zone')}
                        style={{
                          display: 'inline-block',
                          width: 'calc(50% - 8px)',
                          margin: '0 8px',
                        }}
                      >
                        <Select
                          placeholder={this.props.t('citizens.create-edit.form.zone-select')}
                          mode="multiple"
                        >
                          {zonesOptions}
                        </Select>
                      </Form.Item>
                    </Form.Item>
                    <ButtonPrimary
                      color="blue"
                      shape="round"
                      size="large"
                      htmlType="submit"
                    >
                      {this.props.t('citizens.create-edit.add-button')}
                    </ButtonPrimary>
                  </Form>
                </Card>
              </Spin>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(UserCreate);
