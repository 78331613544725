import React, { Component } from 'react';
import Card from 'components/Card.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, Row, Col } from 'react-bootstrap';
import { Spin, Table, notification, Breadcrumb, Input, Form, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ButtonPrimary from 'components/Buttons/ButtonPrimary.jsx';
import {
  deleteSpecialLicensePlate,
  getAllSpecialLicensePlatesFromCenter,
  getSpecialLicensePlatesFiltered,
} from 'repository/specialLicensePlate/SpecialLicensePlateRepository';
import { withTranslation } from 'react-i18next';
import { getCenterEntitiesByCenter } from 'util/APIUtils';
import moment from 'moment';
import 'moment/locale/es';
import { MdAdd, MdFireTruck } from 'react-icons/md';
import ButtonLink from 'components/Buttons/ButtonLink';
import { TbTrashXFilled } from 'react-icons/tb';
moment.locale('es');
const FormItem = Form.Item;
const Option = Select.Option;

class SpecialLicensePlates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      centerName: localStorage.getItem('center'),
      specialLicensePlateList: [],

      centerEntitySearch: undefined,
      specialLicensePlateSearch: undefined,
      resetButton: true,
      centerEntitiesOptions: [],

      totalSpecialLicensePlates: 0,
    };

    this.columns = [
      {
        title: this.props.t('special-license-plates.list.table.plate'),
        dataIndex: 'licensePlate',
        key: 'licensePlate',
      },
      {
        title: this.props.t('special-license-plates.list.table.center-entity'),
        dataIndex: 'centerEntity',
        key: 'centerEntity',
        render: (text, record) => {
          return <span>{record.centerEntityName}</span>;
        },
      },
      {
        title: this.props.t('special-license-plates.list.table.created-date'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: {
          compare: (a, b) => moment(a.createdAt) - moment(b.createdAt),
          multiple: 1,
        },
        render: (text, record) => {
          return moment(record.createdAt).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('special-license-plates.list.table.creator'),
        dataIndex: 'user',
        key: 'user',
        render: (text, record) => {
          return <span>{record.username}</span>;
        },
      },
      {
        title: this.props.t('special-license-plates.list.table.delete'),
        dataIndex: 'acciones',
        width: '150px',
        align: 'center',
        render: (text, record) => {
          return (
            <ButtonLink
              color="black"
              onClick={() => {
                this.warningWithConfirmAndCancelMessage(record.id, record.licensePlate);
              }}
            >
              <TbTrashXFilled style={{ fontSize: '30' }} />
            </ButtonLink>
          );
        },
      },
    ];

    this.hideAlert = this.hideAlert.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);
  }

  componentDidMount() {
    this.updateSpecialLisencePlatesTable();

    getCenterEntitiesByCenter(this.state.centerName).then((response) => {
      this.setState({ centerEntitiesOptions: response });
    });
  }

  updateSpecialLisencePlatesTable() {
    this.setState({ loading: true });

    getAllSpecialLicensePlatesFromCenter(this.state.centerName)
      .then((response) => {
        this.setState({
          specialLicensePlateList: response,
          loading: false,
          totalSpecialLicensePlates: response.length,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  warningWithConfirmAndCancelMessage(specialLicensePlateId, specialLicensePlate) {
    this.setState({
      deleteAlert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('special-license-plates.list.delete-modal.title')}
          onConfirm={() => this.successDelete(specialLicensePlateId, specialLicensePlate)}
          onCancel={() => this.cancelDelete()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText={this.props.t('special-license-plates.list.delete-modal.confirm-button')}
          cancelBtnText={this.props.t('special-license-plates.list.delete-modal.cancel-button')}
          showCancel
        />
      ),
    });
  }

  successDelete(specialLicensePlateId, specialLicensePlate) {
    this.removeSpecialLicensePlate(specialLicensePlateId, specialLicensePlate);
  }

  cancelDelete() {
    this.setState({
      deleteAlert: (
        <SweetAlert
          danger
          style={{ display: 'block' }}
          title={this.props.t('special-license-plates.list.cancel-modal.title')}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.props.t('special-license-plates.list.cancel-modal.message')}
        </SweetAlert>
      ),
    });
  }

  removeSpecialLicensePlate(specialLicensePlateId, specialLicensePlate) {
    deleteSpecialLicensePlate(this.state.centerName, specialLicensePlate, specialLicensePlateId)
      .then(() => {
        this.hideAlert();
        notification['success']({
          message: 'ZinkinData Portal',
          description: this.props.t('special-license-plates.list.success-delete'),
        });

        this.updateSpecialLisencePlatesTable();
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: this.props.t('special-license-plates.list.error-delete'),
        });
      });
  }

  hideAlert() {
    this.setState({
      deleteAlert: null,
    });
  }

  getFilteredSpecialLicensePlates() {
    this.setState({ loading: true });

    getSpecialLicensePlatesFiltered(
      this.state.specialLicensePlateSearch,
      this.state.centerName,
      this.state.centerEntitySearch
    )
      .then((response) => {
        this.setState({ specialLicensePlateList: response, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  resetForm() {
    this.setState({
      centerEntitySearch: undefined,
      specialLicensePlateSearch: undefined,
      resetButton: true,
    });
    this.updateSpecialLisencePlatesTable();
  }

  getCenterEntitiesByCenter() {}

  render() {
    if (this.props.currentUser.role !== 'ROLE_ADMIN' && this.props.currentUser.role !== 'ROLE_SUPERADMIN') {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      const centerEntitiesOptions = this.state.centerEntitiesOptions.map((record) => (
        <Option value={record.name}>{record.name}</Option>
      ));

      return (
        <div className="main-content">
          {this.state.deleteAlert}
          <Grid fluid>
            <Breadcrumb
              separator=">"
              style={{ marginBottom: '10px', fontSize: 20 }}
            >
              <Breadcrumb.Item>
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i
                    style={{
                      lineHeight: '30px',
                      verticalAlign: 'middle',
                      marginRight: '5px',
                      fontSize: '30px',
                    }}
                  >
                    <MdFireTruck />
                  </i>
                  {this.props.t('sidebar.special-license-plates')} <b>({this.state.totalSpecialLicensePlates})</b>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Card
                    content={
                      <div>
                        <Row>
                          <Col md={8}>
                            <Form
                              className="filter-form"
                              layout="inline"
                            >
                              <FormItem>
                                <Select
                                  showSearch
                                  size="large"
                                  style={{ height: '40px', width: '220px' }}
                                  optionFilterProp="children"
                                  placeholder={this.props.t('special-license-plates.list.table.center-entity')}
                                  onChange={(value) => {
                                    if (typeof value !== 'undefined') {
                                      this.setState({
                                        centerEntitySearch: value,
                                        resetButton: false,
                                      });
                                    } else {
                                      this.setState({
                                        centerEntitySearch: undefined,
                                      });
                                    }
                                  }}
                                  onSelect={(value) => {
                                    this.setState({
                                      centerEntitySearch: value,
                                      resetButton: false,
                                    });
                                  }}
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  allowClear
                                  value={this.state.centerEntitySearch}
                                  defaultActiveFirstOption={false}
                                >
                                  {centerEntitiesOptions}
                                </Select>
                              </FormItem>
                              <FormItem>
                                {
                                  <Input
                                    size="large"
                                    placeholder={this.props.t('special-license-plates.list.table.plate')}
                                    name="specialLicensePlateSearch"
                                    value={this.state.specialLicensePlateSearch}
                                    onChange={(event) => {
                                      this.setState({
                                        specialLicensePlateSearch: event.target.value,
                                      });
                                      this.setState({ resetButton: false });
                                    }}
                                  />
                                }
                              </FormItem>
                              <FormItem>
                                <ButtonPrimary
                                  onClick={() => {
                                    this.getFilteredSpecialLicensePlates();
                                    this.setState({ previousButton: true });
                                  }}
                                  type="primary"
                                  shape="round"
                                  size="large"
                                  disabled={this.state.searchButton}
                                  style={{ width: '100%' }}
                                >
                                  {this.props.t('special-license-plates.list.search-button')}
                                </ButtonPrimary>
                              </FormItem>
                              <FormItem>
                                <ButtonPrimary
                                  style={{ width: '100%' }}
                                  onClick={() => {
                                    this.resetForm();
                                  }}
                                  disabled={this.state.resetButton}
                                  color="black"
                                  shape="round"
                                  size="large"
                                >
                                  {this.props.t('special-license-plates.list.reset-button')}
                                </ButtonPrimary>{' '}
                              </FormItem>
                            </Form>
                          </Col>
                          <Col md={4}>
                            <ButtonPrimary
                              color="blue"
                              shape="round"
                              size="large"
                              style={{ float: 'right', marginRight: '10px' }}
                              onClick={() => this.props.history.push('/special-license-plates-add')}
                            >
                              {this.props.t('special-license-plates.list.add-button')}
                              <MdAdd
                                style={{
                                  verticalAlign: 'sub',
                                  fontSize: '20',
                                  marginLeft: '5px',
                                }}
                              />
                            </ButtonPrimary>
                          </Col>
                        </Row>
                        <Table
                          dataSource={this.state.specialLicensePlateList}
                          columns={this.columns}
                          pagination={{ pageSize: 10 }}
                        />
                      </div>
                    }
                  />
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(SpecialLicensePlates);
